export const VehicleTypes = {
  tractor: {
    short: "Т",
    full: "тягач"
  },
  trailer: {
    short: "П",
    full: "прицеп"
  },
  truck: {
    short: "Г",
    full: "грузовой автомобиль"
  },
  car: {
    short: "Л",
    full: "легковой автомобиль"
  }
}

export const UsersRoles = {
  Admin: {
    short: 'А',
    full: 'администратор'
  },
  Owner: {
    short: 'В',
    full: 'владелец'
  },
  Manager: {
    short: 'М',
    full: 'менеджер'
  },
  Accountant: {
    short: 'Б',
    full: 'бухгалтер'
  }
}
export const ContractorTypes = {
  Forwarder: {
    short: 'Э',
    full: 'экспедитор'
  },
  Shipper: {
    short: 'Г',
    full: 'грузоотправитель'
  },
  Supplier: {
    short: 'П',
    full: 'поставщик'
  }
}

export const PaymentTermEvents = {
  load: 'загрузке',
  unload: 'выгрузке',
  papers: 'оригиналам док-тов',
  scan: 'копиям док-тов',
}

export const OrderStatuses = {
  new: 'Новый',
  process: 'В работе',
  closed: 'Завершен'
}

export const MileageTypes = {
  start: 'Новая заявка',
  add_load: 'Частичная загрузка',
  load: 'Полная загрузка',
  add_unload: 'Частичная выгрузка',
  unload: 'Полная выгрузка',
  end: 'Конец рейса'
}

export const PostServices = {
  np: 'Новая почта',
  up: 'Укрпочта',
  c: 'Курьер'
}

export const EmptyRunRatio = 0.1

export const TMS_USER_ID = 'ctms-user-id';
export const TMS_AUTH_TOKEN = 'ctms-auth-token';

const DEV_MODE = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
//const DEV_MODE = false;
export const API_HOST = DEV_MODE ? 'http://api.comfort.tms:8888' : 'https://tms-api.ppcomfort.com.ua'; // 'https://tms-api.ppcomfort.com.ua'
export const API_URI = API_HOST+'/api';
