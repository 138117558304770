import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from '../LoadingSpinner'
import ApiService from '../ApiService'
import {
  Button,
  Input, FormGroup, Label,
  Col,
  Alert,
  Table } from 'reactstrap'

class MaintenanceSave extends Component {
  constructor(props) {
    super(props)
    this.API = new ApiService();
    let today = new Date();
    today = today.getFullYear()+'-'+String(today.getMonth()+1).padStart(2,'0')+'-'+String(today.getDate()).padStart(2,'0')
    this.state = {
      dataLoaded: false,
      mData: {vehicle: {licence_plate: ''}},
      showDate: today
    }
  }

  componentDidMount() {
    this.initData()
  }

  render () {
    return (
      <div>
      <h3><FontAwesomeIcon icon="tools" /> Обслуживание {this.state.mData.vehicle.licence_plate}</h3>
      <FormGroup row>
        <Col sm={3}>
          <Input type="date" value={this.state.showDate} onChange={this.setDate} />
        </Col>
      </FormGroup>
      </div>
    )
  }

  setDate = (e) => {
    let showDate = e.target.value;
    this.setState({showDate})
  }

  initData() {
    this.API.get('/maintenance/operations')
      .then(mData=>{
        this.setState({
          mData,
          dataLoaded: true
        })
      })
  }
}

export default MaintenanceSave
