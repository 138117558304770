import React from 'react'
import Helpers from './Helpers'

const ErrorDescriptions = [
  {
    message: 'Failed to fetch',
    description: 'Проблема с доступом к серверу. Возможно нет доступа к интренету.'
  }
]

function ErrorPage(props) {
  document.body.classList.add('login-page');
  console.log(props.error);
  return (
    <div className="error-page">
      <h1>Произошла ошибка</h1>
      <div className="error-details">
        {ErrorDescription(props.error.message)}
      </div>
    </div>
  )
}

function ErrorDescription (message) {
  for (let i=0;i<ErrorDescriptions.length;i++) {
    if (ErrorDescriptions[i].message === message) {
      return ErrorDescriptions[i].description;
    }
  }
  return message
}

export default ErrorPage
