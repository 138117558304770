import React, { Component } from 'react'
import AuthService from './AuthService'

export default function WithAuth(AuthComponent) {
  const Auth = new AuthService();

  return class AuthWrapped extends Component {
    constructor (props) {
      super(props);
      this.state = {
        user: null
      }
    }

    componentDidMount() {
      if (!Auth.loggedIn()) {
        this.props.history.replace('/login')
      }
      else {
        try {
          const profile = Auth.getProfile()
          this.setState({
            user: profile
          })
        }
        catch(err){
          Auth.logout()
          this.props.history.replace('/login')
        }
      }
    }

    render() {
      if (this.state.user) {
        return (
          <AuthComponent user={this.state.user} {...this.props} />
        )
      }
      else {
        return null
      }
    }
  }
}
