import React from 'react';
import { Page, Text, View, Font, Document, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import Helpers from '../../Helpers'

// Create styles
Font.register({ family: 'Roboto', fonts: [
 { src: '/font/Roboto-Regular.ttf' },
 { src: '/font/Roboto-Bold.ttf', fontWeight: 700 },
]});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    fontSize: '11pt',
    padding: 20
  },
  section: {
    marginBottom: 10
  },
  textsection:{
    flexDirection: 'column',
    marginBottom: 10
  },
  line: {
    flexDirection: 'row',
    marginVertical: 4,
  },
  caption:{
    textDecoration: 'underline',
    width: '90pt'
  },
  datacell: {

  },
  col:{
    width: '50%',
    padding: 10
  },
  bold:{
    fontWeight: 700
  },
  heading: {
    borderBottomStyle: 'solid',
    boredrBottomColor: 'black',
    borderBottomWidth: '0.8pt',
    fontSize: '14pt',
    fontWeight: 700,
    marginTop: 20
  },
  signature: {
    borderTopStyle: 'solid',
    boredrTopColor: 'black',
    borderTopWidth: '0.5pt',
    marginTop: 12
  },
  thead:{
    backgroundColor: '#ccc'
  },
  note: {
    fontSize: 8
  },
  tdwidth: [
    {width: '4%'},
    {width: '51%'},
    {width: '8%'},
    {width: '9%'},
    {width: '14%'},
    {width: '14%'}
  ]
});

const Table = styled.View`
  display: table; width:auto; borderStyle: solid; borderWidth: 1; borderRightWidth: 0; borderBottomWidth: 0;
`

const TR = styled.View`
  flexDirection: row; margin:auto
`

const TD = styled.View`
  borderStyle: solid; borderWidth: 1; borderLeftWidth: 0; borderTopWidth: 0;
`

const SUM = styled.View`
  borderStyle: solid; borderWidth: 1; borderLeftWidth: 0; borderTopWidth: 0; borderBottomWidth: 0; width: '86%';
`

const TT = styled.Text`
  margin: auto; marginTop: 5; fontSize 10;
`

// Create Document Component
const PrintAct = (props) => {
  let D = props.data
  return (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.heading}>АКТ надання послуг №{D.document_number} від {Helpers.formatDate(D.document_date)}</Text>
      </View>
      <View style={styles.section}>
        <Text>
          {D.account.full_name}, надалі {D.contractor.contract_contractor_name},{D.account.signatory !== '' ? ' в особі '+D.account.signatory+',' : ''} що діє на підставі {D.account.signatory_basis}, з одного боку,
          та {D.contractor.full_name}, надалі {D.contractor.contract_customer_name},
          {D.contractor.signatory !== '' ? ' в особі '+D.contractor.signatory+',' : ''} що діє на підставі {D.contractor.signatory_basis}, склали цей акт про те, що {D.contractor.contract_contractor_name} згідно
          з {D.order.contractor_order} виконав наступні роботи (надав такі послуги):
        </Text>
      </View>
      <View style={styles.section}>
        <Table>
        <TR style={styles.thead}>
          <TD style={styles.tdwidth[0]}><TT>№</TT></TD>
          <TD style={styles.tdwidth[1]}><TT>Найменування послуги</TT></TD>
          <TD style={styles.tdwidth[2]}><TT>Кіл-сть</TT></TD>
          <TD style={styles.tdwidth[3]}><TT>Од.</TT></TD>
          <TD style={styles.tdwidth[4]}><TT>Ціна {D.account.vat>0 ? 'з' : 'без'} ПДВ</TT></TD>
          <TD style={styles.tdwidth[5]}><TT>Разом {D.account.vat>0 ? 'з' : 'без'} ПДВ</TT></TD>
        </TR>
        <TR>
          <TD style={styles.tdwidth[0]}><TT>1</TT></TD>
          <TD style={styles.tdwidth[1]}><TT style={{marginLeft: 2}}>{D.document_service_name}</TT></TD>
          <TD style={styles.tdwidth[2]}><TT>1</TT></TD>
          <TD style={styles.tdwidth[3]}><TT>послуга</TT></TD>
          <TD style={styles.tdwidth[4]}><TT>{D.order.paymentAmount.toFixed(2)}</TT></TD>
          <TD style={styles.tdwidth[5]}><TT>{D.order.paymentAmount.toFixed(2)}</TT></TD>
        </TR>
        </Table>
        <TR>
          <SUM><TT style={{marginRight: 2}}>Разом без ПДВ</TT></SUM>
          <TD style={styles.tdwidth[4]}><TT>{D.account.vat>0 ? D.order.VAT.novat.toFixed(2) : D.order.paymentAmount.toFixed(2)}</TT></TD>
        </TR>
        {D.account.vat>0 ? <TR>
          <SUM><TT style={{marginRight: 2}}>ПДВ {D.account.vat}%</TT></SUM>
          <TD style={styles.tdwidth[4]}><TT>{D.order.VAT.vat.toFixed(2)}</TT></TD>
        </TR> : <TR />}
        <TR>
          <SUM><TT style={{marginRight: 2, fontWeight:700}}>Всього</TT></SUM>
          <TD style={[styles.tdwidth[4], {fontWeight:700}]}><TT>{D.order.paymentAmount.toFixed(2)}</TT></TD>
        </TR>

      </View>
      <View style={styles.section}>
        <Text>Всього на суму {Helpers.amountAsWord(D.order.paymentAmount)}</Text>
        <Text>{D.account.vat>0 ? 'В т.ч. ПДВ '+Helpers.amountAsWord(D.order.VAT.vat) : 'Без ПДВ'}</Text>
      </View>
      <View style={[styles.section, {marginTop: 20, paddingBottom: 20, borderBottomStyle: 'solid', boredrBottomColor: 'black', borderBottomWidth: '0.8pt'}]}>
        <Text>
          Сторони не мають взаємних претензій по якості отриманих послуг (виконаних робіт) і вважають їх виконаними (наданими) у повному обсязі.{"\n\n"}
          Місце складання: {D.account.sign_place}
        </Text>
      </View>
      <View style={styles.textsection}>
        <View style={styles.line}>
          <View style={styles.col}>
            <Text>
              {D.contractor.contract_contractor_name}*{"\n"}
              <Text style={styles.bold}>{D.account.full_name}</Text>{"\n\n"}
              {D.account.reg_number!==D.account.tax_number ? 'ЄДРПОУ: '+D.account.reg_number+"\n" : ''}
              {D.account.tax_number!=="" ? 'ІПН: '+D.account.tax_number+"\n" : ''}
              IBAN: {D.account.iban+"\n"}
              {D.account.bank+"\n"}
              Адреса: {D.account.address_reg+"\n"}
              {D.account.phone}
            </Text>
          </View>
          <View style={styles.col}>
            <Text>
              {D.contractor.contract_customer_name}*{"\n"}
              <Text style={styles.bold}>{D.contractor.full_name}</Text>{"\n\n"}
              {D.contractor.reg_number!==D.account.tax_number ? 'ЄДРПОУ: '+D.contractor.reg_number+"\n" : ''}
              {D.contractor.tax_number!=="" ? 'ІПН: '+D.contractor.tax_number+"\n" : ''}
              IBAN: {D.contractor.iban+"\n"}
              {D.contractor.bank+"\n"}
              Адреса: {D.contractor.address+"\n"}
              {D.contractor.phone_number}
            </Text>
          </View>
        </View>
        <View style={styles.line}>
          <View style={styles.col}>
            <Text>{D.account.signatory_position==='' ? "\n" : D.account.signatory_position}</Text>
            <Text style={styles.signature}>
              {D.account.signatory_name}
              {"\n"}
              <Text style={styles.note}>*відповідальний за здійснення господарської операції та правильність її оформлення</Text>
              {"\n"}
              {Helpers.formatDate(D.document_date)}
            </Text>
          </View>
          <View style={styles.col}>
            <Text>{D.contractor.signatory_position}</Text>
            <Text style={styles.signature}>
              {D.contractor.signatory_name}
              {"\n"}
              <Text style={styles.note}>*відповідальний за здійснення господарської операції та правильність її оформлення</Text>
              {"\n"}
              {Helpers.formatDate(D.document_date)}
            </Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
)}

export default PrintAct
