import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import ApiService from '../ApiService'
import LoadingSpinner from '../LoadingSpinner'
import {PaymentTermEvents} from '../../params'
import Helpers from '../Helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    Table,
    Badge, Button,
    Col,Row
} from 'reactstrap'

var paymentTermType = {
  bank: 'Б/Д',
  calendar: 'К/Д'
}

class OrdersView extends Component {
  state = {
    dataLoaded: false,
    id: 0,
    routeStart: '',
    routeFinish: '',
    routeStartDate: '',
    routeFinishDate: '',
    routeCargo: '',
    routeWeight: 0,
    driver_name: '',
    driver_lname: '',
    driver_pname: '',
    vehicle: '',
    trailer: '',
    contractor: '',
    contractor_order: '',
    paymentAmount: 0,
    paymentTerm: 0,
    paymentTermType: 0,
    paymentTermEvent: 0,
    paymentTermDate: '',
    paid_on: ''
  }

  constructor (props) {
    super(props);
    this.API = new ApiService();
  }

  componentDidMount () {
    this.nodeId = (this.props.match.params.nodeId === undefined) ? false : this.props.match.params.nodeId;
    this.API.bindDataGet('/orders/getview/'+this.nodeId, this.updateState);
  }

  render () {
    return (<div>
      {!this.state.dataLoaded && <LoadingSpinner />}
      <Row>
        <Col lg={6}><h2>Заявка №{this.state.id}</h2></Col>
        <Col lg={6}>
          <Button color="primary" tag={Link} to={"/orders/save/"+this.state.id}><FontAwesomeIcon icon="pen" /> Редактировать</Button>
        </Col>
      </Row>

      <Table className="order-view" bordered>
      <tbody>
        <tr>
          <th className="header" colSpan={6}>Маршрут</th>
        </tr>
        <tr>
          <th>Загрузка:</th>
          <td>{Helpers.formatDate(this.state.routeStartDate)} в {this.state.routeStart}</td>
          <th>Выгрузка:</th>
          <td>{Helpers.formatDate(this.state.routeFinishDate)} в {this.state.routeFinish}</td>
          <th>Груз:</th>
          <td>{this.state.routeCargo}, {(this.state.routeWeight/1000).toFixed(2)} т</td>
        </tr>
        <tr>
          <th className="header" colSpan={6}>Исполнитель</th>
        </tr>
        <tr>
          <th>Водитель:</th>
          <td>{this.state.driver_lname} {this.state.driver_name.substring(0,1)}. {this.state.driver_pname.substring(0,1)}.</td>
          <th>ТС:</th>
          <td colSpan={3}>
            {this.state.vehicle}
            {this.state.trailer && <span> / {this.state.trailer}</span>}
          </td>
        </tr>
        <tr>
          <th className="header" colSpan={6}>Заказчик</th>
        </tr>
        <tr>
          <td colSpan={6}>{this.state.contractor}</td>
        </tr>
        <tr>
          <th>Заявка заказчика</th>
          <td colSpan={5}>{this.state.contractor_order}</td>
        </tr>
        <tr>
          <th className="header" colSpan={6}>Оплата</th>
        </tr>
        <tr>
          <th>Ставка</th>
          <td>{this.state.paymentAmount}</td>
          <th>Условия</th>
          <td>{this.state.paymentTerm} {paymentTermType[this.state.paymentTermType]} по {PaymentTermEvents[this.state.paymentTermEvent]}</td>
          <th>План</th>
          <td>{Helpers.formatDate(this.state.paymentTermDate)} {this.state.paid_on && <Badge color="success">{Helpers.formatDate(this.state.paid_on)}</Badge>}</td>
        </tr>
      </tbody>
      </Table>
      </div>)
  }

  updateState = (key, value) => {
    if (value !== undefined) {
      this.setState({[key]: value});
    } else {
      for (var k in key) {
        if (this.state[k]!==undefined) {
          this.setState({[k]: key[k]})
        }
      }
      this.setState({dataLoaded: true})
    }
  }
}

export default OrdersView
