import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import WithAuth from '../Login/WithAuth'
import ApiService from '../ApiService'
import {
    Container, Col, Row,
    Button, ButtonGroup,
    Alert,
    Table,
    Collapse,
    Label, Form, FormGroup
  } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from '../LoadingSpinner'
import Helpers from '../Helpers'

import OrdersNavigation from './OrdersNavigation'
import {PaymentTermEvents, OrderStatuses} from '../../params'

class Orders extends Component {
  state = {
    successAlert: false,
    dataLoaded: false,
    collapse: {},
    orders: []

  }

  constructor () {
    super()
    this.API = new ApiService();
    this.toggleDetails = this.toggleDetails.bind(this)
  }

  componentDidMount () {
    var loc = this.props.history.location
    if (loc.state && loc.state.nodeSaved) {
      this.setState({successAlert: true})
      this.props.history.location.state.nodeSaved = false;
      setTimeout(function() { this.setState({successAlert: false}) }.bind(this), 3000);
    }

    this.getList()
  }

  render() {
    if (!this.state.dataLoaded) {
      return <LoadingSpinner />
    }
    return (
      <div>
        <Alert className="tmp-alert" isOpen={this.state.successAlert} color="success">Заявка сохранена</Alert>
        <OrdersNavigation />
        <div>
        <Button className="float-right m-2" color="primary" tag={Link} to="/orders/save"><FontAwesomeIcon icon="plus" />{' '}Новая заявка</Button>
        </div>
        <Form>
        <Table className="orders-list">
        <thead>
        <tr>
          <th colSpan="2">{ }</th>
          <th>Загрузка</th>
          <th>ТС</th>
          <th>Водитель</th>
          <th>Заказчик</th>
          <th>Маршрут</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {this.state.orders.map((node) =>
          [
          <tr key={node.id} onClick={(e)=>this.toggleDetails(node.id,e)}>
            <td className={node.status === 'new' ? 'bg-warning' : 'bg-success'}>{ }</td>
            <td className="collapsed"><FontAwesomeIcon size="lg" icon={this.state.collapse['id'+node.id]?'angle-down':'angle-right'} /></td>
            <td>{Helpers.formatDate(node.routeStartDate)}</td>
            <td>{node.vehicle_licence_plate}{node.trailer_licence_plate!=="" && <p>{node.trailer_licence_plate}</p>}</td>
            <td>{node.driver_lname} {node.driver_name.substring(0,1)}. {node.driver_pname.substring(0,1)}.</td>
            <td>{node.contractor}</td>
            <td>{node.routeStart} - {node.routeFinish} ({node.routeCargo}, {node.routeWeight/1000} т.)</td>
            <td>
            <ButtonGroup>
              <Button color="warning" size="sm" tag={Link} to={"/orders/docs/"+node.id} title="Документы"><FontAwesomeIcon icon="copy" /></Button>
              <Button color="success" size="sm" tag={Link} to={"/orders/milage/"+node.id} title="Пробег"><FontAwesomeIcon icon="tachometer-alt" /></Button>
              <Button color="primary" size="sm" tag={Link} to={"/orders/save/"+node.id} title="Редактировать"><FontAwesomeIcon icon="pen" /></Button>
              <Button color="danger" size="sm" node-id={node.id} title="Удалить" onClick={this.deleteNode}><FontAwesomeIcon icon="trash" /></Button>
            </ButtonGroup>
            </td>
          </tr>,
          <Collapse isOpen={this.state.collapse['id'+node.id]} tag="tr" key="clps{node.id}">
            <td colSpan="8" className="order-details">
              <Container>
              <Row>
                <Col sm={6}>
                  <strong>Ставка:</strong>
                  {' '}{node.paymentAmount} грн. ({node.paymentTerm} {node.paymentTermType==='bank'?'Б':' К'}/Д по {PaymentTermEvents[node.paymentTermEvent]})
                  </Col>
                  <Col sm={6}>
                  <FormGroup row>
                    <Label for={'orderStatus'+node.id}><strong>Статус:</strong></Label>
                    <Col>
                    <ButtonGroup>
                      {Object.keys(OrderStatuses).map(item_id =>
                         <Button color="primary" className="btn-sm" onClick={this.updateOrderStatus} key={"os"+item_id} name={item_id} node-id={node.id} active={node.status===item_id}>{OrderStatuses[item_id]}</Button>
                     )}
                    </ButtonGroup>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              </Container>
            </td>
          </Collapse>
        ]

        )}
        </tbody>
        </Table>
        </Form>
      </div>
    )
  }

  toggleDetails (id, e) {
    if (e.target.parentNode.tagName === 'BUTTON' || e.target.tagName === 'BUTTON') {
      return true;
    }
    var stateColapse = this.state.collapse
    if (stateColapse['id'+id] == null || typeof stateColapse['id'+id] === 'undefined') stateColapse['id'+id] = false
    stateColapse['id'+id] = !stateColapse['id'+id]
    this.setState({ collapse: stateColapse })
  }

  getList () {
    this.API.get('/orders/list')
      .then(orders=>{
        if(typeof orders === 'object') {
        this.setState({
          orders,
          dataLoaded: true
        })
      }})
  }

  updateOrderStatus = (e) => {
    e.preventDefault();
    if ( ! window.confirm("Изменить статус заявки?")){
      return false;
    }
    const nodeId = e.target.getAttribute('node-id') ? Number(e.target.getAttribute('node-id')) : Number(e.target.parentNode.getAttribute('node-id'));
    const status = e.target.getAttribute('name') ? e.target.getAttribute('name') : e.target.parentNode.getAttribute('name');
    const orders = this.state.orders;
    var nodeIndex = -1;
    orders.find((element, i) => {
      if (element.id === nodeId) {
        nodeIndex = i
        orders[i].status = status
      }
    })
    this.API.put('/orders/updatestatus/'+nodeId, {status})
      .then((response) => {
        if(response.status!==status){
          orders[nodeIndex].status = status
        } else if (status=='closed') {
          orders.splice(nodeIndex, 1)
        }
        this.setState({orders})
      })
  }

  deleteNode = (e) => {
    const nodeId = Number(Helpers.findAttribute(e.target, 'node-id'))
    const orders = this.state.orders;
    let nodeIndex = null
    let node = orders.find((element, i) => {
      if (element.id === nodeId) {
        nodeIndex = i
        return element
      }
      return false
    })
    let msg = 'Удалить заявку на '+Helpers.formatDate(node.routeStartDate)+' '+node.routeStart+'-'+node.routeFinish+' для '+node.driver_lname+' '+node.driver_name.substring(0,1)+'. '+node.driver_pname.substring(0,1)+'.?'
    if (window.confirm(msg)) {
      this.API.delete('/orders/'+nodeId)
        .then((response) => {
          if (response){
            orders.splice(nodeIndex, 1);
            this.setState({orders})
          }
      })
    }
  }
}

const DataRow = ({data, action}) => (
  <tr>
    <td>{data.truck} {data.trailer!=="" && " / "+data.trailer}</td>
    <td>{data.driver}</td>
    <td>{data.routeStart} - {data.routeFinish}</td>
  </tr>
)

export default WithAuth(Orders)
