import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Header from './Header'
import SideBar from './SideBar'
import WithAuth from './Login/WithAuth';
import {
  Container,
  Row,
  Col } from 'reactstrap'

import { library } from '@fortawesome/fontawesome-svg-core'
import
  { faRoute, faAddressBook, faTruck, faIdCard, faTools, faUsers, faUserCircle, faSignOutAlt,
     faPen, faTrash, faTachometerAlt, faCopy, faPlus, faSpinner, faAngleRight, faAngleDown,
     faWrench, faCar, faTrailer, faSearch, faArrowLeft, faPrint, faFileExport, faCheck,
     faEllipsisV, faMoneyCheckAlt, faEnvelope, faDollarSign, faTimes, faChartLine, faHandshake,
     faWallet, faBed, faSignInAlt, faClock, faRetweet
  }
  from '@fortawesome/free-solid-svg-icons'

import ErrorBoundary from './ErrorBoundary'

import Dashboard from './Dashboard'
import Fleet from './Fleet'
import FleetSave from './Fleet/FleetSave'
import Drivers from './Drivers'
import DriversSave from './Drivers/DriversSave'
import Contractors from './Contractors'
import ContractorsSave from './Contractors/ContractorsSave'
import Orders from './Orders'
import OrdersUnpaid from './Orders/OrdersUnpaid'
import OrdersNoDocs from './Orders/OrdersNoDocs'
import OrdersArchive from './Orders/OrdersArchive'
import OrdersView from './Orders/OrdersView'
import OrdersSave from './Orders/OrdersSave'
import OrdersMileage from './Orders/OrdersMileage'
import OrdersDocs from './Orders/OrdersDocs'
import OrdersPrint from './Orders/OrdersPrint'
import Maintenance from './Maintenance'
import MaintenanceSave from './Maintenance/MaintenanceSave'
import Reports from './Reports'
import Expenses from './Expenses'
import Contacts from './Contacts'
import ContactsSave from './Contacts/ContactsSave'

library.add(faRoute, faAddressBook, faTruck, faIdCard, faTools, faUsers,
            faUserCircle, faSignOutAlt, faPen, faTrash, faTachometerAlt,
            faCopy, faPlus, faSpinner, faAngleRight, faAngleDown, faWrench,
            faCar, faTrailer, faSearch, faArrowLeft, faPrint, faFileExport,
            faCheck, faEllipsisV, faMoneyCheckAlt, faEnvelope, faDollarSign,
            faTimes, faChartLine, faHandshake, faWallet, faBed, faSignInAlt,
            faClock, faRetweet
          );

class DashboardLayout extends Component {

  render () {
    return (
      <div>
      <Header />
      <Container fluid>
        <Row>
          <SideBar />
          <Col xs={10}>
            <main>
            <Switch>
            <ErrorBoundary>
              <Route path="/" component={Dashboard} exact />
              <Route path="/fleet" component={Fleet} exact />
              <Route path="/fleet/save/:nodeId?" component={FleetSave} />
              <Route path="/drivers" component={Drivers} exact />
              <Route path="/drivers/save/:nodeId?" component={DriversSave} />
              <Route path="/contractors" component={Contractors} exact />
              <Route path="/contractors/save/:nodeId?" component={ContractorsSave} />
              <Route path="/orders" component={Orders} exact />
              <Route path="/orders/unpaid" component={OrdersUnpaid} exact />
              <Route path="/orders/nodocs" component={OrdersNoDocs} exact />
              <Route path="/orders/archive" component={OrdersArchive} exact />
              <Route path="/orders/view/:nodeId" component={OrdersView} exact />
              <Route path="/orders/save/:nodeId?" component={OrdersSave} />
              <Route path="/orders/milage/:nodeId" component={OrdersMileage} />
              <Route path="/orders/docs/:nodeId" component={OrdersDocs} />
              <Route path="/orders/print/:docType/:nodeId" component={OrdersPrint} />
              <Route path="/maintenance" component={Maintenance} exact />
              <Route path="/maintenance/save/:nodeId?/:date?" component={MaintenanceSave} exact />
              <Route path="/reports/:reportType?" component={Reports} />
              <Route path="/contacts" component={Contacts} exact />
              <Route path="/contacts/save/:nodeId?" component={ContactsSave} />
              <Route path="/expenses" component={Expenses} exact />
              </ErrorBoundary>
            </Switch>
            </main>
          </Col>
        </Row>
      </Container>
      </div>
    )
  }
}

export default WithAuth(DashboardLayout)
