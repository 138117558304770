import React, { Component } from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom'

import Login from './components/Login'
import DashboardLayout from './components/DashboardLayout'


class App extends Component {
  render() {
    return (
      <Switch>
        <Route path="/login" component={Login} exact />
        <Route path="/logout" component={Login} exact />
        <Route path="/" component={DashboardLayout} />
      </Switch>
    );
  }
}

export default App;
