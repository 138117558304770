import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ApiService from '../ApiService'
import {
  Form,
  Input,
  Button,
  Table
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from '../LoadingSpinner'
import { MileageTypes } from '../../params'
import Helpers from '../Helpers'

class OrdersMileage extends Component {
  constructor (props) {
    super(props);
    this.API = new ApiService();

    this.state = {
      dataLoaded: false,
      order_data: {
        order: {
          id: '',
          routeStart: '',
          routeStartDate: '',
          routeFinish:''
        },
        docs: [],
        mileage: []
      },
      type: '',
      odometer: '',
      fuel: '',
      weight: '',
      comment: '',
      submitButton: {
        type: 'plus',
        spin: false,
        disabled: false
      },
      MileageTypesOptions: []
    }

  }

  componentWillMount () {
    const nodeId = (this.props.match.params.nodeId === undefined) ? false : this.props.match.params.nodeId;
    this.API.bindDataGet('/orders/mileage/'+nodeId, this.updateState, 'order_data');
  }

  render() {
    const order = this.state.order_data.order
    let mileage = this.state.order_data.mileage
    let total = {
      toload: 0,
      loaded: 0,
      return: 0,
      total: 0
    }
    for (let i in mileage){
      if (mileage[i-1]) {
        mileage[i].mileage = mileage[i].odometer - mileage[i-1].odometer
      } else {
          mileage[i].mileage = 0
      }
      switch (mileage[i].type) {
        case 'end':
          total.return = mileage[i].mileage
          break;
        default:
          if (total.toload === 0) {
            total.toload = mileage[i].mileage
          } else {
            total.loaded +=mileage[i].mileage
          }
          break;
      }
    }

    total.total = total.toload+total.loaded+total.return
    if (total.total > 0) {
      let cEmptyRunRatio = (total.toload+total.return)/total.total
    }

    return (
      <div>
      <Button tag={Link} to="/orders" outline color="info" size="sm"><FontAwesomeIcon icon="arrow-left" /> К списку заявок</Button>
      <br/><br/>
      <h2>Пробег по заявке №{order.id}</h2>
      <h6>{Helpers.formatDate(order.routeStartDate)} {order.routeStart} - {order.routeFinish} ({order.vehicle}, {order.driver_lname})</h6>
      <Form onSubmit={(this._handleSubmit)}>
      <Table className="mileage-table">
      <thead>
      <tr>
        <th>Тип</th>
        <th>Одометр/Пробег</th>
        <th>Топливо</th>
        <th>Груз, кг</th>
        <th>Комментарий</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      {mileage.map((node) => [
        <tr key={node.id}>
          <td>{MileageTypes[node.type]}</td>
          <td>{node.odometer}{node.mileage>0 ? ' / '+node.mileage : ''}</td>
          <td>{node.fuel}</td>
          <td>{node.weight}</td>
          <td>{node.comment}</td>
          <td><Button color="danger" type="button" title="Удалить" node-id={node.id} onClick={this.deleteNode}><FontAwesomeIcon icon="trash" node-id={node.id} /></Button></td>
        </tr>
      ])}
      </tbody>
      <thead>
      <tr>
        <td>
          <Input type="select" name="type" required value={this.state.type} onChange={Helpers.updateState.bind(this)} id="slctType">
          {this.state.MileageTypesOptions}
          </Input>
        </td>
        <td><Input placeholder="Одометр..." name={Helpers.autocompleteOff('odometer')} value={this.state.odometer} type="number" required onChange={Helpers.updateState.bind(this)} /></td>
        <td><Input placeholder="Остаток топлива..." name={Helpers.autocompleteOff('fuel')} value={this.state.fuel} type="number" onChange={Helpers.updateState.bind(this)} /></td>
        <td><Input placeholder="Вес груза..." name={Helpers.autocompleteOff('weight')} value={this.state.weight} type="number" required onChange={Helpers.updateState.bind(this)} /></td>
        <td><Input placeholder="Комментарий..." name="comment" value={this.state.comment} onChange={Helpers.updateState.bind(this)} /></td>
        <td><Button color="primary" disabled={this.state.submitButton.disabled}><FontAwesomeIcon icon={this.state.submitButton.type} spin={this.state.submitButton.spin} /></Button></td>
      </tr>
      </thead>
      </Table>
      </Form>
      <Table>
      <thead>
      <tr>
        <th>Общий пробег</th>
        <th>Подача</th>
        <th>С грузом</th>
        <th>Возврат</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{total.total}</td>
        <td>{total.toload}</td>
        <td>{total.loaded}</td>
        <td>{total.return}</td>
      </tr>
      </tbody>
      </Table>
      <Button tag={Link} to="/orders" outline color="info" size="sm"><FontAwesomeIcon icon="arrow-left" /> К списку заявок</Button>
      {!this.state.dataLoaded ? <LoadingSpinner /> : null}
      </div>
    )
  }

  createMileageTypesOptions = (o) => {
    if (o === undefined) o = MileageTypes
    this.setState({MileageTypesOptions: Helpers.buildSelectOptions(o)})
  }

  deleteNode = (e) => {
    const nodeId = e.target.getAttribute('node-id') ? Number(e.target.getAttribute('node-id')) : Number(e.target.parentNode.getAttribute('node-id'));
    const mileages = this.state.order_data.mileage;
    let node = mileages.find((element) => {
      if (element.id === nodeId) {
        return element
      }
      return false
    })
    let msg = 'Удалить точку "'+MileageTypes[node.type]+'" - '+node.odometer+'км?'
    if (window.confirm(msg)) {
      this.API.delete('/orders/mileage/'+this.state.order_data.order.id+'/'+nodeId)
        .then((response) => {
          if (response){
            const order_data = this.state.order_data
            order_data.mileage = response
            this.updateState('order_data', order_data)
          }
      })
    }
  }

  updateState = (key, value) => {
    this.setState({[key]: value});
    if (key === 'order_data'){
      let mt = Helpers.cloneObject(MileageTypes)
      for (let i in value.mileage) {
        switch (value.mileage[i].type) {
          case 'start':
            delete mt.start
            break;
          case 'end':
            delete mt.end
            break;
          case 'load':
            delete mt.load
            //delete mt.add_load
            break;
          case 'unload':
            delete mt.unload
            //delete mt.add_unload
            break;
          default:
        }
      }
      this.createMileageTypesOptions(mt)
      this.setState({dataLoaded: true})
    }
  }

  _handleSubmit = (e) => {
    e.preventDefault();
    const {type, odometer, fuel, comment, weight} = this.state
    const {vehicle_id, trailer_id} = this.state.order_data.order
    const data2submit = {type, odometer, fuel, comment, weight, vehicle_id, trailer_id}
    this.updateState('submitButton',{
      type: 'spinner',
      spin: true,
      disabled: true
    })

    this.API.post('/orders/mileage/'+this.state.order_data.order.id+'/add', data2submit)
      .then((response) => {
        if (response.length > 0){
          const order_data = this.state.order_data
          order_data.mileage = response
          this.updateState('order_data', order_data)
          this.setState({
            type: '',
            odometer: '',
            fuel: '',
            weight: '',
            comment: '',
            submitButton: {
              type: 'plus',
              spin: false,
              disabled: false
            }
          })
        }
    })
  }
}

export default OrdersMileage
