import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Table, Badge
} from 'reactstrap'
import ApiService from '../ApiService'
import LoadingSpinner from '../LoadingSpinner'
import Helpers from '../Helpers'

class ReportReceivable extends Component {
  monthes = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']

  state = {
    dataLoaded: false,
    debts: {},
    contractors: {},
    accounts: {},
    pathname: '',
    details: {
      account: 0,
      contractor: 0
    }
  }

  constructor(props) {
    super(props)
    this.API = new ApiService();
    this.clickGo = Helpers.clickGo.bind(this)
    ReceivableList = ReceivableList.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate() {
    this.loadData()
  }

  render() {
    if (!this.state.dataLoaded) {
      return <LoadingSpinner />
    }
    if(this.state.details.account>0){
      return <ContractorDebts debts={this.state.debts} />
    } else {
      return <ReceivableList debts={this.state.debts} />
    }
  }

  getQS() {
    let qs = this.props.history.location.search.substring(1);
    return new URLSearchParams(qs);
  }

  loadData() {
    let pathname = this.props.history.location.pathname
    if (pathname!=this.state.pathname) {
      this.setState({pathname})
      if (this.props.match.params.account>0){
        this.loadContractorDebts()
      } else {
        this.loadAllDebts()
      }
    }
  }

  loadContractorDebts() {
    let {account,contractor} = this.props.match.params
    if(account>0 && contractor>0 && account!=this.state.details.account && contractor!=this.state.details.contractor){
      this.setState({
        details: {
          account,
          contractor
        },
        dataLoaded: false
      })
      this.API.get('/report/receivable?a='+account+'&c='+contractor)
              .then(debts=>{
                this.setState({debts, dataLoaded: true})
              })
      return true
    } else {
      return false
    }
  }

  loadAllDebts() {
    this.setState({
      details: {}
    })
    this.API.get('/report/receivable')
            .then(debts=>{
              this.setState({debts, dataLoaded: true})
            })
  }
}

function ReceivableList (props) {
  let debts = props.debts
  if (typeof props.debts.map != 'function'){
    return false
  }
  let cAccount = 0;
  let cSum = 0;
  let total = 0;
  return (<div className="report-receivable">
  <Table hover size="sm">
    <tbody>
      {debts.map(debt=>{
        let tr = []
        if (debt.account_id!==cAccount) {
          if (cSum > 0) {
            tr.push(<tr key={'sum'+debt.account_id}><th className="text-right">Всего:</th><th>{cSum.toLocaleString('ru-RU')}</th></tr>)
            cSum = 0;
          }
          tr.push(<tr key={'acc'+debt.account_id}><th colSpan={2} className="head">{debt.account}</th></tr>)
          cAccount = debt.account_id
        }
        cSum += debt.debt*1
        total += debt.debt*1
        tr.push(
          <tr key={'debt'+debt.contractor_id} onClick={()=>{this.clickGo('/reports/receivable/'+debt.account_id+'/'+debt.contractor_id)}}>
            <td className="name">{debt.contractor}</td>
            <td>{(debt.debt*1).toLocaleString('ru-RU')}</td>
          </tr>
        )
        return tr
      })}
      <tr><th className="text-right">Всего:</th><th>{cSum.toLocaleString('ru-RU')}</th></tr>
    </tbody>
  </Table>
  <div className="total">
    <h5>Дебиторская задолженность: {total.toLocaleString('ru-RU')}</h5>
  </div>
  </div>)
}

function ContractorDebts (props) {
  let debts = props.debts
  let total = 0;
  return (
    <div className="report-receivable">
    <Table hover>
      <thead>
        <th>#</th>
        <th>Выгрузка</th>
        <th>Сумма</th>
        <th>Водитель</th>
        <th>Маршрут</th>
      </thead>
      <tbody>
        {debts.orders.map(order=>{
          total+=order.paymentAmount
          let invoice = (order.invoice!=null && order.invoice!=0) ? <Badge>Счёт {order.invoice}</Badge> : ''
          let term = ''
          switch (order.late_payment) {
            case 1:
              term = <Badge color="danger">{Helpers.formatDate(order.paymentTermDate)}</Badge>
              break;
            case 0:
              term = <Badge color="success">{Helpers.formatDate(order.paymentTermDate)}</Badge>
              break;
          }
          return (
            <tr key={'o'+order.id}>
              <td className="id"><Link to={"/orders/view/"+order.id} target="_blank">{order.id}</Link></td>
              <td>{Helpers.formatDate(order.routeFinishDate)}<br/>{term}</td>
              <td>{order.paymentAmount.toLocaleString('ru-RU')}<br/>{invoice}</td>
              <td className="driver">{order.driver_lname} {order.driver_name.substring(0,1)}. {order.driver_pname.substring(0,1)}.</td>
              <td className="route">{order.routeStart} - {order.routeFinish}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
    <div className="total">
      <h5>Задолженность {debts.contractor} перед {debts.account} - {total.toLocaleString('ru-RU')}</h5>
    </div>
    </div>
  )
}

export default ReportReceivable
