import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import WithAuth from '../Login/WithAuth'
import ApiService from '../ApiService'
import {
  FormGroup,Label,Input
} from 'reactstrap'

import ReportDriversCalendar from './ReportDriversCalendar'
import ReportReceivable from './ReportReceivable'
import ReportDriversDuty from './ReportDriversDuty'

class Reports extends Component {
  state = {
    dataLoaded: false,
    loadedReport: ''
  }

  reportTypes = {
    drivers_calendar: 'Календарь работы водителей',
    receivable: 'Дебиторская задолженность',
    drivers_duty: 'Команидировки водителей'
  }

  constructor (props) {
    super(props)
    this.API = new ApiService();
    this.loadReport = this.loadReport.bind(this)
    this.loadedReport = ''
  }

  componentDidMount () {
  }

  render () {
    let loadedReport = this.props.match.params.reportType;
    return (<div>
      <FormGroup>
        <Label for="slctReportType">Вид отчета</Label>
        <Input type="select" onChange={this.loadReport} value={loadedReport}>
          <option value="0">Выберите...</option>
          {Object.keys(this.reportTypes).map((key)=>{
            return <option key={key} value={key}>{this.reportTypes[key]}</option>
          })}
        </Input>
      </FormGroup>
      <Switch>
        <Route path="/reports/drivers_calendar" component={ReportDriversCalendar} exact />
        <Route path="/reports/drivers_duty" component={ReportDriversDuty} exact />
        <Route path={["/reports/receivable","/reports/receivable/:account/:contractor"]} component={ReportReceivable} exact />
      </Switch>
      </div>)
  }

  loadReport (e) {
    let loadReport = e.target.value
    this.props.history.push("/reports/"+loadReport);
  }
}

export default WithAuth(Reports)
