import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import WithAuth from '../Login/WithAuth'
import ApiService from '../ApiService'
import {
  Table,
  Row,Col,
  Badge,
  FormGroup, Input, InputGroup, Label,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, ButtonGroup,
  UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle,
  Alert} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from '../LoadingSpinner'
import Helpers from '../Helpers'

import OrdersNavigation from './OrdersNavigation'

class OrdersNoDocs extends Component {
  state = {
    successAlert: false,
    dataLoaded: false,
    orders: [],
    results: {
      total: 0,
      late: 0,
      vat_need: 0
    },
    results_detailed: [],
    paymentWinOpen: false,
    pwDate: ''
  }

  accounts = []

  constructor () {
    super()
    this.API = new ApiService();
  }

  componentDidMount () {

    this.getList()
  }

  render() {
    if (!this.state.dataLoaded) {
      return <LoadingSpinner />
    }
    return (
      <div>
      <OrdersNavigation active="nodocs" />
      <br/>

      <Table className="orders-list">
      <thead>
      <tr>
        <th>{ }</th>
        <th>{ }</th>
        <th>Получатель</th>
        <th>Выгрузка</th>
        <th>Водитель</th>
        <th>Заказчик</th>
        <th>Маршрут</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      {this.state.orders.map((node) => {

        return <tr key={node.id} data-id={node.id}>
          <td className="id">{node.id}</td>
          <td><Button color="link" className="check" title="Подтвердить получение оригиналов документов" onClick={this.docsRecieved}><FontAwesomeIcon icon="check" /></Button></td>
          <td>{node.account_name}</td>
          <td>{Helpers.formatDate(node.routeFinishDate)}</td>
          <td className='no-wrap'>{node.driver_lname} {node.driver_name.substring(0,1)}. {node.driver_pname.substring(0,1)}.</td>
          <td>{node.contractor}</td>
          <td>{node.routeStart} - {node.routeFinish}</td>
          <td>
          <UncontrolledButtonDropdown size="sm" direction="left">
            <DropdownToggle outline>
              <FontAwesomeIcon icon="ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={this.togglePaymentWin} node-id={node.id}><a href="#"><FontAwesomeIcon icon="dollar-sign" /> Оплата</a></DropdownItem>
              <DropdownItem><Link to={"/orders/docs/"+node.id}><FontAwesomeIcon icon="copy" /> Документы</Link></DropdownItem>
              <DropdownItem><Link to={"/orders/milage/"+node.id}><FontAwesomeIcon icon="tachometer-alt" /> Пробег</Link></DropdownItem>
              <DropdownItem><Link to={"/orders/save/"+node.id}><FontAwesomeIcon icon="pen" /> Редактировать</Link></DropdownItem>
              <DropdownItem><a href="#" onClick={this.deleteNode} node-id={node.id}><FontAwesomeIcon icon="trash" /> Удалить</a></DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
          </td>
        </tr>
      })}
      </tbody>
      </Table>

      <Modal isOpen={this.state.paymentWinOpen} toggle={this.togglePaymentWin}>
        <ModalHeader>Оплата от {this.state.pwContractor} по заявке №{this.state.pwOrderId}</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={4}>Маршрут</Col>
            <Col sm={8}>{this.state.pwRoute}</Col>
          </Row>
          <Row>
            <Col sm={4}>Сумма</Col>
            <Col sm={8}>{this.state.pwAmount} грн</Col>
          </Row>
          <FormGroup row>
          <Label sm={4} for="txtPaymentDate">Дата оплаты</Label>
          <Col sm={8}>
            <Input type="date" name="pwDate" value={this.state.pwDate} onChange={this._updateState} id="txtPaymentDate" />
          </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.savePayment}>Сохранить</Button>{' '}
          <Button color="secondary" onClick={this.togglePaymentWin}>Отмена</Button>
        </ModalFooter>
      </Modal>
      </div>
    )
  }

  _updateState = (e,b,c) => {
    this.setState({[e.target.name]: e.target.value});
  }

  getList () {
    this.API.get('/orders/list/nodocs')
      .then(orders=>{
        if(typeof orders === 'object') {
        this.setState({
          orders,
          dataLoaded: true
        })
      }})
  }

  deleteNode = (e) => {
    e.preventDefault()
    const nodeId = e.target.getAttribute('node-id') ? Number(e.target.getAttribute('node-id')) : Number(e.target.parentNode.getAttribute('node-id'));
    const orders = this.state.orders;
    let nodeIndex = null
    let node = this.findNode(nodeId)
    nodeIndex = node.nodeIndex
    let msg = 'Удалить заявку на '+Helpers.formatDate(node.routeStartDate)+' '+node.routeStart+'-'+node.routeFinish+' для '+node.driver_lname+' '+node.driver_name.substring(0,1)+'. '+node.driver_pname.substring(0,1)+'.?'
    if (window.confirm(msg)) {
      this.API.delete('/orders/'+nodeId)
        .then((response) => {
          if (response){
            orders.splice(nodeIndex, 1);
            this.setState({orders})
          }
      })
    }
  }

  togglePaymentWin = (e) => {
    e.preventDefault();
    let newState = {}
    if (this.state.paymentWinOpen === false) {
      let t = e.target;
      let nodeId = e.target.getAttribute('node-id');
      while (nodeId===null) {
        t = t.parentNode;
        nodeId = t.getAttribute('node-id')
      }
      let node = this.findNode(nodeId)
      newState.pwContractor = node.contractor
      newState.pwAmount = node.paymentAmount
      newState.pwRoute = node.routeStart+' - '+node.routeFinish
      newState.pwOrderId = node.id
      newState.pwDate = node.paid_on
    }

    newState.paymentWinOpen = !this.state.paymentWinOpen
    this.setState(newState)
  }

  savePayment = (e) => {
    e.preventDefault()
    this.API.put('/orders/payment/'+this.state.pwOrderId, {date: this.state.pwDate})
            .then((response) => {
              if (this.state.pwOrderId = response) {
                let orders = this.state.orders
                let node = this.findNode(response)
                orders.splice(node.nodeIndex, 1);
                this.setState({
                  orders,
                  paymentWinOpen: false
                })
              }
            })

  }

  docsRecieved = (e) => {
    e.preventDefault()
    let t = e.target;
    let id = t.getAttribute('data-id')
    while (id===null) {
      t = t.parentNode;
      id = t.getAttribute('data-id')
    }
    let o = this.findNode(id)

    if (window.confirm('Подтвердить получение документов по заявке от '+o.contractor)){
      this.API.put('/orders/docsdone/'+o.id, {})
              .then((response) => {
                if (o.id = response) {
                  let orders = this.state.orders
                  let node = this.findNode(response)
                  orders.splice(node.nodeIndex, 1);
                  this.setState({
                    orders
                  })
                }
              })
    }

  }

  findNode = (id) => {
    id = Number(id)
    let orders = this.state.orders

    let r = orders.find((element, i) => {
      if (element.id === id) {
        element.nodeIndex = i
        return true;
      }
    })
    return r
  }
}

export default WithAuth(OrdersNoDocs)
