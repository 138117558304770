import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import ApiService from '../ApiService'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { VehicleTypes } from '../../params'
import LoadingSpinner from '../LoadingSpinner'

class FleetSave extends Component {
  state = {
  }
  constructor (props) {
    super(props);
    this.API = new ApiService();
    this.nodeId = 0;
    this.state = {
      saved: false,
      dataLoaded: false,
      licencePlate: '',
      make: '',
      model: '',
      productionYear: '',
      type: '',
      mileage: ''
    }

  }

  componentWillMount () {
    this.nodeId = (this.props.match.params.nodeId === undefined) ? 0 : this.props.match.params.nodeId;
    if (this.nodeId > 0) {
      this.API.bindDataGet('/fleet/get/'+this.nodeId, this.setExistedData);
    } else {
      this.setState({dataLoaded: true})
    }
  }

  render() {
    if (this.state.saved)
    {
      return <Redirect to={{pathname: '/fleet', state: {nodeSaved: 'add'}}} />
    }
    const VehicleTypesOptions = []
    VehicleTypesOptions.push(<option value="" key="0">...</option>)
    for (var vehicleType in VehicleTypes){
      VehicleTypesOptions.push(<option value={vehicleType} key={vehicleType}>{VehicleTypes[vehicleType].full}</option>)
    }

    return (
      <div>
      <h2>{!this.nodeId ? "Добавить" : "Редактировать"} ТС</h2>
      <Form onSubmit={(this._handleSubmit)}>
        <FormGroup>
          <Label for="slctType">Тип ТС</Label>
          <Input type="select" name="type" required value={this.state.type} onChange={this._updateState} id="slctType">
            {VehicleTypesOptions}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="txtLicencePlate">Рег. номер</Label>
          <Input type="text" value={this.state.licencePlate} onChange={this._updateState} name="licencePlate" id="txtLicencePlate" pattern="^[a-zA-Z\-0-9]+$" required />
        </FormGroup>
        <FormGroup>
          <Label for="txtMake">Марка</Label>
          <Input type="text" name="make" value={this.state.make} onChange={this._updateState} id="txtMake" required />
        </FormGroup>
        <FormGroup>
          <Label for="txtModel">Модель</Label>
          <Input type="text" name="model" value={this.state.model} onChange={this._updateState} id="txtModel" required />
        </FormGroup>
        <FormGroup>
          <Label for="txtProductionYear">Год выпуска</Label>
          <Input type="number" name="productionYear" value={this.state.productionYear} onChange={this._updateState} id="txtProductionYear" required />
        </FormGroup>
        <FormGroup>
          <Label for="txtmileage">Пробег</Label>
          <Input type="number" name="mileage" value={this.state.mileage} onChange={this._updateState} id="txtmileage" />
        </FormGroup>
        <Button color="primary">Сохранить</Button>{'  '}
        <Button tag={Link} to="/fleet">Отмена</Button>
      </Form>
      {!this.state.dataLoaded ? <LoadingSpinner /> : null}
      </div>
    )
  }

  setExistedData = (data) => {
    for (var key in data) {
      let keyToVar = '';
      let value = '';
      let spacerIndex = key.indexOf('_')
      if (spacerIndex>=0){
        keyToVar = key.substr(0, spacerIndex)+key.substr(spacerIndex+1, 1).toUpperCase()+key.substr(spacerIndex+2);
      } else {
        keyToVar = key
      }
      if (this.state[keyToVar]!==undefined) {
        value = data[key]===null ? '' : data[key]
        this.setState({[keyToVar]: value})
      }
    }
    this.setState({dataLoaded: true})
  }

  _updateState = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  _handleSubmit = (e) => {
    e.preventDefault();
    const data2submit = this.state
    delete data2submit.dataLoaded
    delete data2submit.saved
    if (this.nodeId>0) {
      this.API.put('/fleet/save/'+this.nodeId, data2submit)
        .then((response) => {
          if (response.id){
            this.setState({saved: true})
          }
        })
    } else {
      this.API.post('/fleet/save', data2submit)
        .then((response) => {
          if (response.id){
            this.setState({saved: true})
          }
        })
    }
  }
}

export default FleetSave
