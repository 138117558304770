import React, { Component } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { Link } from 'react-router-dom'
import ApiService from '../ApiService'

import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PrintInvoice from './Print/PrintInvoice'
import PrintAct from './Print/PrintAct'

class OrdersPrint extends Component {
  constructor() {
    super()
    this.API = new ApiService()
    this.state={
      docLoaded: false,
      document: {}
    }
  }

  componentDidMount () {
    const nodeId = (this.props.match.params.nodeId === undefined) ? false : this.props.match.params.nodeId;
    this.doctype = this.props.match.params.docType
    switch (this.doctype) {
      case 'invoice':
      case 'act':
      this.API.get('/print/'+this.doctype+'/'+nodeId, {})
        .then((data) => {this.prepareDocument(data)})
        break;
      default:
    }

  }

  render() {
    return (
      <div>
        <Button tag={Link} to={'/orders/docs/'+this.props.match.params.nodeId} color="link" size="sm" outline className="close-pdf"><FontAwesomeIcon icon="times" /></Button>
        <div className="PDFViewer">
        {this.state.docLoaded ? <PDFViewer>{this.state.document}</PDFViewer> : 'Документ загружается...'}
        </div>
      </div>
    )
  }

  prepareDocument (data) {
    let doctype = this.props.match.params.docType
    let updatedState = {docLoaded: true, document: {}}
    switch (doctype) {
      case 'invoice':
        updatedState.document = <PrintInvoice data={data} />
        break;
      case 'act':
        updatedState.document = <PrintAct data={data} />
        break;
      default:
    }
    this.setState(updatedState)
  }
}
export default OrdersPrint
