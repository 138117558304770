import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import WithAuth from '../Login/WithAuth'
import ApiService from '../ApiService'
import {
  Table,
  Row,Col,
  Badge,
  FormGroup, Input, Label,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, ButtonGroup,
  UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle,
  Alert} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from '../LoadingSpinner'
import Helpers from '../Helpers'

import OrdersNavigation from './OrdersNavigation'

class OrdersUnpaid extends Component {
  state = {
    successAlert: false,
    dataLoaded: false,
    orders: [],
    results: {
      total: 0,
      late: 0,
      vat_need: 0
    },
    results_detailed: [],
    paymentWinOpen: false,
    pwDate: ''
  }

  accounts = []

  constructor () {
    super()
    this.API = new ApiService();
  }

  componentDidMount () {

    this.getList()
  }

  render() {
    if (!this.state.dataLoaded) {
      return <LoadingSpinner />
    }
    return (
      <div>
      <OrdersNavigation active="unpaid" />
      <br/>
      <Row>
        <Col lg={4}>
          <Table size="sm">
          <thead>
          <tr>
            <th>Всего</th>
            <th>{this.state.results.total}</th>
          </tr>
          </thead>
          <tbody>
          {this.accounts.map((acc,i)=>{
          return <tr key={'t'+i}>
            <td>{acc}</td>
            <td>{this.state.results_detailed[i].total}</td>
          </tr>
          })}
          </tbody>
          </Table>
        </Col>
        <Col lg={4}>
          <Table size="sm">
          <thead>
          <tr>
            <th>Просрочено</th>
            <th>{this.state.results.late}</th>
          </tr>
          </thead>
          <tbody>
          {this.accounts.map((acc,i)=>{
          return <tr key={'l'+i}>
            <td>{acc}</td>
            <td>{this.state.results_detailed[i].late}</td>
          </tr>
          })}
          </tbody>
          </Table>
        </Col>
        <Col lg={4}>
          <Table size="sm">
          <thead>
          <tr>
            <th>НДС для регистрации</th>
            <th>{this.state.results.vat_need.toFixed(2)}</th>
          </tr>
          </thead>
          <tbody>
          {this.accounts.map((acc,i)=>{
          return <tr key={'v'+i}>
            <td>{acc}</td>
            <td>{this.state.results_detailed[i].vat_need.toFixed(2)}</td>
          </tr>
          })}
          </tbody>
          </Table>
        </Col>
      </Row>
      <Table className="orders-list">
      <thead>
      <tr>
        <th colSpan="3">{ }</th>
        <th>Срок</th>
        <th>Сумма</th>
        <th>Выгрузка</th>
        <th>Водитель</th>
        <th>Заказчик</th>
        <th>Маршрут</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      {this.state.orders.map((node) => {
        let bg = 'bg-success';
        let tax_color = 'gainsboro'
        let tax_title = 'Налоговая не требуется';
        let docs_color = 'gainsboro'
        let docs_title = 'Отправка документов не требуется для оплаты'
        if (node.paymentTermDate === null) bg='bg-warning';
        else {
          let now = new Date()
          if (Helpers.formatDate(node.paymentTermDate, true) < now) bg = 'bg-danger'
        }
        if (node.vat>0){
          if (node.tax_registred === null) {
            tax_color='firebrick'
            tax_title='Налоговая не зарегистрирована'
          } else {
            tax_color='forestgreen'
            tax_title = 'Налоговая зарегистрирована '+Helpers.formatDate(node.tax_registred)
          }
        }
        if (node.paymentTermEvent == 'papers') {
          if (node.docs_sent === null) {
            docs_color='firebrick'
            docs_title='Документы не отправлены'
          } else {
            docs_color='forestgreen'
            docs_title='Документы отправлены '+Helpers.formatDate(node.docs_sent)
          }
        }
        let acc = this.accounts[node.paymentAccount]
        if (acc.length>8) acc = acc.substring(0,8)+'...'

        return <tr key={node.id}>
          <td className={bg}>{ }</td>
          <td className="id">{node.id}</td>
          <td>
            <FontAwesomeIcon icon="envelope" color={docs_color} title={docs_title} />
            <FontAwesomeIcon icon="money-check-alt" color={tax_color} title={tax_title} />
          </td>
          <td>{Helpers.formatDate(node.paymentTermDate)}</td>
          <td>{node.paymentAmount.toLocaleString('ru-RU')}<br /><Badge title={this.accounts[node.paymentAccount]}>{acc}</Badge></td>
          <td>{Helpers.formatDate(node.routeFinishDate)}<br /><Badge>{(node.invoice!=0 && node.invoice!=null) && 'Счет №'+node.invoice}</Badge></td>
          <td className='no-wrap'>{node.driver_lname} {node.driver_name.substring(0,1)}. {node.driver_pname.substring(0,1)}.</td>
          <td>{node.contractor}</td>
          <td className="route">{node.routeStart} - {node.routeFinish}</td>
          <td>
          <UncontrolledButtonDropdown size="sm" direction="left">
            <DropdownToggle outline>
              <FontAwesomeIcon icon="ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={this.togglePaymentWin} node-id={node.id}><a href="#"><FontAwesomeIcon icon="dollar-sign" /> Оплата</a></DropdownItem>
              <DropdownItem><Link to={"/orders/docs/"+node.id}><FontAwesomeIcon icon="copy" /> Документы</Link></DropdownItem>
              <DropdownItem><Link to={"/orders/milage/"+node.id}><FontAwesomeIcon icon="tachometer-alt" /> Пробег</Link></DropdownItem>
              <DropdownItem><Link to={"/orders/save/"+node.id}><FontAwesomeIcon icon="pen" /> Редактировать</Link></DropdownItem>
              <DropdownItem><a href="#" onClick={this.deleteNode} node-id={node.id}><FontAwesomeIcon icon="trash" /> Удалить</a></DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
          </td>
        </tr>
      })}
      </tbody>
      </Table>

      <Modal isOpen={this.state.paymentWinOpen} toggle={this.togglePaymentWin}>
        <ModalHeader>Оплата от {this.state.pwContractor} по заявке №{this.state.pwOrderId}</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={4}>Маршрут</Col>
            <Col sm={8}>{this.state.pwRoute}</Col>
          </Row>
          <Row>
            <Col sm={4}>Сумма</Col>
            <Col sm={8}>{this.state.pwAmount} грн</Col>
          </Row>
          <FormGroup row>
          <Label sm={4} for="txtPaymentDate">Дата оплаты</Label>
          <Col sm={8}>
            <Input type="date" name="pwDate" value={this.state.pwDate} onChange={this._updateState} id="txtPaymentDate" />
          </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.savePayment}>Сохранить</Button>{' '}
          <Button color="secondary" onClick={this.togglePaymentWin}>Отмена</Button>
        </ModalFooter>
      </Modal>
      </div>
    )
  }

  _updateState = (e,b,c) => {
    this.setState({[e.target.name]: e.target.value});
  }

  getList () {
    this.API.get('/orders/list/unpaid')
      .then(orders=>{
        if(typeof orders === 'object') {
        let results = {
          total: 0,
          late: 0,
          vat_need: 0
        }
        let results_detailed = []
        let now = new Date()
        orders.map((order) => {
          order.paymentAmount = Number(order.paymentAmount)
          if (this.accounts[order.paymentAccount] === undefined) {
            this.accounts[order.paymentAccount] = order.account_name
            results_detailed[order.paymentAccount] = {total:0, late:0, vat_need: 0}
          }
          results_detailed[order.paymentAccount].total += order.paymentAmount
          results.total += order.paymentAmount
          if (Helpers.formatDate(order.paymentTermDate, true) < now && order.paymentTermDate!==null) {
            results.late += order.paymentAmount
            results_detailed[order.paymentAccount].late += order.paymentAmount
          }
          if (order.vat>0 && order.tax_registred === null) {
            results.vat_need += order.paymentAmount/6;
            results_detailed[order.paymentAccount].vat_need += order.paymentAmount/6;
          }
        })
        this.setState({
          orders,
          results,
          results_detailed,
          dataLoaded: true
        })
      }})
  }

  deleteNode = (e) => {
    e.preventDefault()
    const nodeId = e.target.getAttribute('node-id') ? Number(e.target.getAttribute('node-id')) : Number(e.target.parentNode.getAttribute('node-id'));
    const orders = this.state.orders;
    let nodeIndex = null
    let node = this.findNode(nodeId)
    nodeIndex = node.nodeIndex
    let msg = 'Удалить заявку на '+Helpers.formatDate(node.routeStartDate)+' '+node.routeStart+'-'+node.routeFinish+' для '+node.driver_lname+' '+node.driver_name.substring(0,1)+'. '+node.driver_pname.substring(0,1)+'.?'
    if (window.confirm(msg)) {
      this.API.delete('/orders/'+nodeId)
        .then((response) => {
          if (response){
            orders.splice(nodeIndex, 1);
            this.setState({orders})
          }
      })
    }
  }

  togglePaymentWin = (e) => {
    e.preventDefault();
    let newState = {}
    if (this.state.paymentWinOpen === false) {
      let t = e.target;
      let nodeId = e.target.getAttribute('node-id');
      while (nodeId===null) {
        t = t.parentNode;
        nodeId = t.getAttribute('node-id')
      }
      let node = this.findNode(nodeId)
      newState.pwContractor = node.contractor
      newState.pwAmount = node.paymentAmount
      newState.pwRoute = node.routeStart+' - '+node.routeFinish
      newState.pwOrderId = node.id
      newState.pwDate = node.paid_on
    }

    newState.paymentWinOpen = !this.state.paymentWinOpen
    this.setState(newState)
  }

  savePayment = (e) => {
    e.preventDefault()
    this.API.put('/orders/payment/'+this.state.pwOrderId, {date: this.state.pwDate})
            .then((response) => {
              if (this.state.pwOrderId = response) {
                let orders = this.state.orders
                let node = this.findNode(response)
                orders.splice(node.nodeIndex, 1);
                this.setState({
                  orders,
                  paymentWinOpen: false
                })
              }
            })

  }

  findNode = (id) => {
    id = Number(id)
    let orders = this.state.orders
    let r = orders.find((element, i) => {
      if (element.id === id) {
        element.nodeIndex = i
        return true;
      }
      return false
    })
    return r
  }
}

export default WithAuth(OrdersUnpaid)
