import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import WithAuth from '../Login/WithAuth'
import ApiService from '../ApiService'

import {
  Form, FormGroup, Input,
  Button,
  Row, Col
  } from 'reactstrap'
import DatePicker from 'react-date-picker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from '../LoadingSpinner'
import Helpers from '../Helpers'

class Expenses extends Component {
  state = {
    dataLoaded: false,
    period: {
      start: '',
      end: ''
    }

  }

  constructor () {
    super()
    this.API = new ApiService();
  }

  componentDidMount () {
    let now = new Date()
    let last_day = new Date(now.getFullYear(), now.getMonth()+1, 0)
    let period = {
      start: new Date(now.getFullYear(), now.getMonth(), 1),
      end: new Date(now.getFullYear(), now.getMonth(), last_day.getDate())
    }
    this.setState({period})
  }

  render () {
    return (
      <div>
        <h3><FontAwesomeIcon icon="wallet" />
        Расходы с
        <DatePicker className='exp-period' calendarClassName='exp-period-calendar' name="period_start" value={this.state.period.start} format="dd.MM.y" clearIcon={null} onChange={date => this.updatePedriod(date, 'start')} calendarIcon={null} />
        по
        <DatePicker className='exp-period' calendarClassName='exp-period-calendar' name="period_end" value={this.state.period.end} format="dd.MM.y" clearIcon={null} onChange={date => this.updatePedriod(date, 'end')} calendarIcon={null} />
        </h3>
      </div>
    )
  }

  updatePedriod = (date, type) => {
    let period = this.state.period
    period[type] = date
    this.setState({period})
  }
}

export default WithAuth(Expenses)
