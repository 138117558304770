import React from 'react';
import { Page, Text, View, Font, Document, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import Helpers from '../../Helpers'

// Create styles
Font.register({ family: 'Roboto', fonts: [
 { src: '/font/Roboto-Regular.ttf' },
 { src: '/font/Roboto-Bold.ttf', fontWeight: 700 },
]});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    fontSize: '11pt',
    padding: 20
  },
  section: {
    marginBottom: 10
  },
  textsection:{
    flexDirection: 'column',
    marginBottom: 10
  },
  line: {
    flexDirection: 'row',
    marginVertical: 4,
  },
  caption:{
    textDecoration: 'underline',
    width: '18%'
  },
  datacell: {
    width: '82%'
  },
  bold:{
    fontWeight: 700
  },
  heading: {
    borderBottomStyle: 'solid',
    boredrBottomColor: 'black',
    borderBottomWidth: '0.8pt',
    fontSize: '14pt',
    fontWeight: 700
  },
  thead:{
    backgroundColor: '#ccc'
  },
  tdwidth: [
    {width: '5%'},
    {width: '50%'},
    {width: '15%'},
    {width: '15%'},
    {width: '15%'}
  ]
});

const Table = styled.View`
  display: table; width:auto; borderStyle: solid; borderWidth: 1; borderRightWidth: 0; borderBottomWidth: 0;
`

const TR = styled.View`
  flexDirection: row; margin:auto
`

const TD = styled.View`
  borderStyle: solid; borderWidth: 1; borderLeftWidth: 0; borderTopWidth: 0;
`

const SUM = styled.View`
  borderStyle: solid; borderWidth: 1; borderLeftWidth: 0; borderTopWidth: 0; borderBottomWidth: 0; width: '85%';
`

const TT = styled.Text`
  margin: auto; marginTop: 5; fontSize 10;
`

// Create Document Component
const PrintInvoice = (props) => {
  let D = props.data
  return (
  <Document>
    <Page size="A4" style={styles.page}>
    {D.contractor.new_bank_details_alert ? <View style={styles.section}>
      <Text style={{fontWeight: 700,fontSize:'16pt'}}>УВАГА! Змінено банківські реквізити!</Text>
    </View> : <View></View>}
      <View style={styles.section}>
        <Text style={styles.heading}>Рахунок на оплату №{D.document_number} від {Helpers.formatDate(D.document_date)}</Text>
      </View>
      <View style={styles.textsection}>
        <View style={styles.line}>
          <Text style={styles.caption}>Постачальник:</Text>
          <Text style={styles.datacell}>
            <Text style={styles.bold}>{D.account.full_name}{"\n"}</Text>
            <Text>
              {D.account.reg_number!==D.account.tax_number ? 'ЄДРПОУ: '+D.account.reg_number+"\n" : ''}
              {D.account.tax_number!=="" ? 'ІПН: '+D.account.tax_number+"\n" : ''}
              IBAN: <Text style={styles.bold}>{D.account.iban}</Text>{"\n"}
              {D.account.bank+"\n"}
              Адреса: {D.account.address_reg+"\n"}
              {D.account.phone}
            </Text>
          </Text>
        </View>
        <View style={styles.line}>
          <Text style={styles.caption}>Платник:</Text>
          <Text style={styles.datacell}>
            <Text style={styles.bold}>{D.contractor.full_name+"\n"}</Text>
            <Text>
              ЄДРПОУ: {D.contractor.reg_number+"\n"}
              {D.contractor.tax_number!=='' ? 'ІПН: '+D.contractor.tax_number+"\n" : ''}
              {D.contractor.phone_number}
            </Text>
          </Text>
        </View>
        <View style={styles.line}>
          <Text style={styles.caption}>Підстава:</Text>
          <Text style={styles.datacell}>
            <Text>{D.order.contractor_order}</Text>
          </Text>
        </View>
      </View>
      <View style={styles.section}>
        <Table>
        <TR style={styles.thead}>
          <TD style={styles.tdwidth[0]}><TT>№</TT></TD>
          <TD style={styles.tdwidth[1]}><TT>Найменування послуги</TT></TD>
          <TD style={styles.tdwidth[2]}><TT>Кількість</TT></TD>
          <TD style={styles.tdwidth[3]}><TT>Ціна {D.account.vat>0 ? 'з' : 'без'} ПДВ</TT></TD>
          <TD style={styles.tdwidth[4]}><TT>Разом {D.account.vat>0 ? 'з' : 'без'} ПДВ</TT></TD>
        </TR>
        <TR>
          <TD style={styles.tdwidth[0]}><TT>1</TT></TD>
          <TD style={styles.tdwidth[1]}><TT style={{marginLeft: 2}}>{D.document_service_name}</TT></TD>
          <TD style={styles.tdwidth[2]}><TT>1 послуга</TT></TD>
          <TD style={styles.tdwidth[3]}><TT>{D.order.paymentAmount.toFixed(2)}</TT></TD>
          <TD style={styles.tdwidth[4]}><TT>{D.order.paymentAmount.toFixed(2)}</TT></TD>
        </TR>
        </Table>
        <TR>
          <SUM><TT style={{marginRight: 2}}>Разом без ПДВ</TT></SUM>
          <TD style={styles.tdwidth[4]}><TT>{D.account.vat>0 ? D.order.VAT.novat.toFixed(2) : D.order.paymentAmount.toFixed(2)}</TT></TD>
        </TR>
        {D.account.vat>0 ? <TR>
          <SUM><TT style={{marginRight: 2}}>ПДВ {D.account.vat}%</TT></SUM>
          <TD style={styles.tdwidth[4]}><TT>{D.order.VAT.vat.toFixed(2)}</TT></TD>
        </TR> : <TR />}
        <TR>
          <SUM><TT style={{marginRight: 2, fontWeight:700}}>Всього</TT></SUM>
          <TD style={[styles.tdwidth[4], {fontWeight:700}]}><TT>{D.order.paymentAmount.toFixed(2)}</TT></TD>
        </TR>

      </View>
      <View style={styles.section}>
        <Text>Всього на суму {Helpers.amountAsWord(D.order.paymentAmount)}</Text>
        <Text>{D.account.vat>0 ? 'В т.ч. ПДВ '+Helpers.amountAsWord(D.order.VAT.vat) : 'Без ПДВ'}</Text>
      </View>
      <View style={[styles.section, {marginTop: 40, marginRight: 0, textAlign: 'right'}]}>
        <Text >Виписав _________________________________________________</Text>
      </View>
    </Page>
  </Document>
)}

/*


*/

export default PrintInvoice
