import React, { Component } from 'react'
import WithAuth from '../Login/WithAuth'
import ApiService from '../ApiService'
import Helpers from '../Helpers'
import { Link } from 'react-router-dom'
import {
  Button, ButtonGroup,
  Alert,
  Table
  } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from '../LoadingSpinner'


class Drivers extends Component {
  instanceName = "drivers";
  constructor(props) {
    super(props);
    this.API = new ApiService();
    this.state = {
      successAlert: false,
      dataLoaded: false,
      items_list: []
    }
  }

  componentDidMount () {
    this.list();
  }

  render() {
    return (
      <div>
        <Alert className="tmp-alert" isOpen={this.state.successAlert} color="success">Водитель сохранено</Alert>
        <h3><FontAwesomeIcon icon="id-card" /> Водители</h3>
        <Button className="float-right m-2" color="primary" tag={Link} to={"/"+this.instanceName+"/save"}><FontAwesomeIcon icon="plus" /></Button>
        <Table striped className="mt-1 left items-list">
        <thead>
          <tr>
            <th>Ф.И.О.</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {this.state.items_list.map((node) =>
        <tr key={this.instanceName+node.id}>
          <td>{node.last_name+" "+node.first_name+" "+node.patronymic_name}</td>
          <td>
            <ButtonGroup>
              <Button color="primary" size="sm" tag={Link} title="Редактировать" to={"/"+this.instanceName+"/save/"+node.id}><FontAwesomeIcon icon="pen" /></Button>
              <Button color="danger" size="sm" node-id={node.id} title="Удалить" onClick={this.deleteNode}><FontAwesomeIcon icon="trash" /></Button>
            </ButtonGroup>
          </td>
        </tr>
      )}
      </tbody>
    </Table>
    {!this.state.dataLoaded ? <LoadingSpinner /> : null}
    </div>
    )
  }

  list() {
    this.API.get('/'+this.instanceName)
      .then(items_list=>{
        this.setState({
          items_list,
          dataLoaded: true
        })
      })
  }

  deleteNode = (e) => {
    const nodeId = Number(Helpers.findAttribute(e.target, 'node-id'));
    const items_list = this.state.items_list;
    let nodeIndex = null
    let node = items_list.find((element, i) => {
      if (element.id === nodeId) {
        nodeIndex = i
        return element
      } else {
        return false
      }
    })
    let msg = 'Удалить водителя '+node.last_name+' '+node.first_name+' '+node.patronymic_name+'?'
    if (window.confirm(msg)) {
      this.API.delete('/'+this.instanceName+'/'+nodeId)
        .then((response) => {
          if (response){
            items_list.splice(nodeIndex, 1);
            this.setState({items_list})
          }
      })
    }
  }
}

export default WithAuth(Drivers)
