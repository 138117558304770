import React, { Component } from 'react'
import WithAuth from '../Login/WithAuth'
import ApiService from '../ApiService'
import {
  Table,
  Row,Col,
  FormGroup, Input, InputGroup, Label,
  Button, ButtonGroup,
  Alert} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from '../LoadingSpinner'
import Helpers from '../Helpers'

import OrdersNavigation from './OrdersNavigation'

class OrdersArchive extends Component {
  state = {
    successAlert: false,
    dataLoaded: true,
    orders: [],
    results_detailed: [],
    paymentWinOpen: false,
    pwDate: '',
    startDate: '',
    endDate: '',
    account: '0',
    accountsList: []
  }

  accounts = []

  constructor () {
    super()
    this.API = new ApiService();
    this.getList = this.getList.bind(this)
  }

  componentWillMount () {
    this.getList()
  }

  render() {
    if (!this.state.dataLoaded) {
      return <LoadingSpinner />
    }
    return (
      <div>
      <OrdersNavigation active="archive" />
      <br/>
      <Row>
        <Col lg={3}>
        <FormGroup>
          <Label for="txtStartDate">Начало периода</Label>
          <Input type="date" value={this.state.startDate} onChange={this._updateState} name="startDate" id="txtStartDate" required />
        </FormGroup>
        </Col>
        <Col lg={3}>
        <FormGroup>
          <Label for="txtEndDate">Конец периода</Label>
          <Input type="date" value={this.state.endDate} onChange={this._updateState} name="endDate" id="txtEndDate" required />
        </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <Label for="slctAccount">Получатель</Label>
            <Input type="select" value={this.state.account} onChange={this._updateState} name="account" id="slctAccount" required>
              <option value="0"></option>
              {this.state.accountsList && Object.keys(this.state.accountsList).map(item_id =>
                 <option value={item_id} key={"ac"+item_id}>{this.state.accountsList[item_id]}</option>
             )}
            </Input>
          </FormGroup>
        </Col>
        <Col lg={1}>
          <FormGroup>
            <Label>&nbsp;</Label>
            <Button color="primary" onClick={this.getList}>Показать</Button>
          </FormGroup>
        </Col>
      </Row>

      <Table className="orders-list" size="sm" hover>
      <thead>
      <tr>
        <th>Выгрузка</th>
        <th>Акт</th>
        <th>Заказчик</th>
        <th>Водитель</th>
        <th>Маршрут</th>
      </tr>
      </thead>
      <tbody>
      {this.state.orders.map((node) => {

        return <tr key={node.id} data-id={node.id}>
          <td>{Helpers.formatDate(node.routeFinishDate)}</td>
          <td>{node.act}</td>
          <td>{node.contractor}</td>
          <td className='no-wrap'>{node.driver_lname} {node.driver_name.substring(0,1)}. {node.driver_pname.substring(0,1)}.</td>
          <td>{node.routeStart} - {node.routeFinish}</td>
        </tr>
      })}
      </tbody>
      </Table>
      </div>
    )
  }

  _updateState = (e,b,c) => {
    this.setState({[e.target.name]: e.target.value});
  }

  getList () {
    let url = '/orders/archive/'+this.state.account
              +'/'+(this.state.startDate!='' ? this.state.startDate : '0')
              +'/'+(this.state.endDate!='' ? this.state.endDate : '0')
    this.API.get(url)
      .then(data=>{
        if(typeof data === 'object') {
        this.setState({
          orders: data.orders,
          accountsList: data.accounts,
          dataLoaded: true
        })
      }})
  }


  findNode = (id) => {
    id = Number(id)
    let orders = this.state.orders

    let r = orders.find((element, i) => {
      if (element.id === id) {
        element.nodeIndex = i
        return true;
      }
    })
    return r
  }
}

export default WithAuth(OrdersArchive)
