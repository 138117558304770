import {API_URI} from '../params'
import AuthService from './Login/AuthService'

class ApiService {
  constructor () {
    this.Auth = new AuthService()
    this.fetch = this.fetch.bind(this)
  }

  get(endpoint, params) {
    return this.fetch(endpoint, 'GET', params);
  }

  post(endpoint, params) {
    return this.fetch(endpoint, 'POST', params);
  }

  put(endpoint, params) {
    return this.fetch(endpoint, 'PUT', params);
  }

  delete(endpoint, params) {
    return this.fetch(endpoint, 'DELETE', params);
  }

  fetch(endpoint, method, params) {
    var fetch_uri = new URL(API_URI + endpoint)
    var options = {
      method
    }
    if (typeof params==='object' && Object.keys(params).length>0) {
      if(method === 'GET') {
        Object.keys(params).forEach(key => fetch_uri.searchParams.append(key, params[key]))
      } else {
        options.body = JSON.stringify({
            ...params
        })
      }
    }

    return this.Auth.fetch(fetch_uri, options)
  }

  blob (endpoint) {
    return this.Auth.blob(API_URI+endpoint)
  }

  bindDataGet(endpoint, callback, varKey, params)
  {
    return this.get(endpoint, params)
      .then((responseData, bindTo)=>{
        if (varKey === undefined || varKey==='')
          callback(responseData);
        else
          callback(varKey, responseData);
      })
  }

  error() {

  }
}

export default ApiService
