import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Nav,
  NavItem,
  NavLink  } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class OrdersNavigation extends Component {


  render() {
    return (
      <div>
      <h3><FontAwesomeIcon icon="route" /> Заявки</h3>
      <Nav tabs>
        <NavItem>
          <NavLink tag={Link} to="/orders" active={this.props.active==="default"}>В работе</NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/orders/unpaid" active={this.props.active==="unpaid"}>Без оплаты</NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/orders/nodocs" active={this.props.active==="nodocs"}>Без документов</NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/orders/archive" active={this.props.active==="archive"}>Архив</NavLink>
        </NavItem>
      </Nav>
      </div>
    )
  }
}

OrdersNavigation.defaultProps = {
  active: "default"
}

export default OrdersNavigation
