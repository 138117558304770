import React from 'react'
import ReactDOM from 'react-dom'
import decode from 'jwt-decode';
import {TMS_AUTH_TOKEN, API_HOST} from '../../params'
import ErrorPage from '../ErrorPage'

export default class AuthService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || API_HOST // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    login(email, password) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/login`, {
            method: 'POST',
            body: JSON.stringify({
                email,
                password
            })
        }).then(res => {
            this.setToken(res.token) // Setting the token in sessionStorage
            return Promise.resolve(res);
        })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from sessionStorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Saves user token to sessionStorage

        window.sessionStorage.setItem(TMS_AUTH_TOKEN, idToken)
    }

    getToken() {
        // Retrieves the user token from sessionStorage
        var token = sessionStorage.getItem(TMS_AUTH_TOKEN)
        if (typeof token!=='string')
          return false
        else
          return token
    }

    logout() {
        // Clear user token and profile data from sessionStorage
        sessionStorage.removeItem(TMS_AUTH_TOKEN);
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
            .catch((e)=>{ReactDOM.render(<ErrorPage error={e} />,document.getElementById('root'))})
    }

    blob (url) {
      const headers = {}
      if (this.loggedIn()) {
          headers['Authorization'] = 'Bearer ' + this.getToken()
      }
      return fetch(url, {
          headers
      })
          .then(this._checkStatus)
          .then(response => response.blob())

    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if ((response.status >= 200 && response.status < 300) || response.status===401 || response.status===404 || response.status===403) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error('Data server error: '+response.statusText)
            error.response = response
            response.json().then(data => {error.message=data.message});
            throw error
        }
    }
}
