import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Form,FormGroup,Label,Input,InputGroup,Button,
  Col, Row,
  Table,
  Badge,
  Tooltip
} from 'reactstrap'
import ApiService from '../ApiService'
import LoadingSpinner from '../LoadingSpinner'

class ReportDriversCalendar extends Component {
  monthes = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']

  state = {
    dataLoaded: false,
    month: (new Date).getMonth(),
    year: new Date().getFullYear(),
    driver: 0,
    drivers: [],
    dates: {},
    orders: {}
  }

  constructor() {
    super()
    this.API = new ApiService();
    this.getMonth = this.getMonth.bind(this)
  }

  componentDidMount() {
    this.API.get('/report/drivers_calendar')
            .then(data=>{
              this.setState({drivers: data,dataLoaded: true})
            })
  }

  render() {
    if (!this.state.dataLoaded) {
      return <LoadingSpinner />
    }
    let years = []
    let cy = new Date().getFullYear()
    for (let i=2020;i<=cy;i++) {
      years.push(<option value={i} key={'y'+i}>{i}</option>)
    }
    return (
      <div>
      <Row>
      <Col lg={4}>
        <FormGroup>
          <Label>Водитель</Label>
          <Input type="select" name="driver" defaultValue={this.state.driver}>
            <option value="0">{'Выберите...'}</option>
          {Object.entries(this.state.drivers).map((node)=>{
            return <option key={'dr'+node[1].id} value={node[1].id}>{node[1].name}</option>
          })}
          </Input>
        </FormGroup>
      </Col>
      <Col lg={5}>
        <FormGroup>
          <Label>Месяц и год</Label>
          <InputGroup>
            <Input type="select" name="month" defaultValue={this.state.month} className="col-lg-6">
            {this.monthes.map((n,m)=>{
              return <option value={m} key={'m'+m}>{n}</option>
            })}
            </Input>
            <Input type="select" name="year" id="year" defaultValue={this.state.year} className="col-lg-6">
            {years}
            </Input>
          </InputGroup>
        </FormGroup>
      </Col>
      <Col lg={2}>
        <FormGroup>
          <Label>&nbsp;</Label><br/>
          <Button color="primary" onClick={this.getMonth}>Показать</Button>
        </FormGroup>
      </Col>
      </Row>
      <DriversCalendar month={this.state.month} year={this.state.year} dates={this.state.dates} orders={this.state.orders} />
      </div>
    )
  }

  getMonth () {
    let payload = {
      year: document.getElementsByName('year')[0].value,
      month: document.getElementsByName('month')[0].value,
      driver: document.getElementsByName('driver')[0].value
    }
    this.setState({
      dataLoaded: false,
      month: payload.month,
      year: payload.year,
      driver: payload.driver
    })
    this.API.get('/report/drivers_calendar', payload)
            .then(data=>{
              this.setState({
                dates: data.dates,
                orders: data.orders,
                dataLoaded: true
              })
            })
  }

  _updateState = (e,b,c) => {
    this.setState({[e.target.name]: e.target.value});
  }
}

function DriversCalendar (props) {
  let monthInfo = new Date(props.year, props.month, 1);
  let firstDay = monthInfo.getDay()
  let lastDate = monthInfo.daysInMonth()
  if (firstDay==0) firstDay = 7;
  let td = []
  let tr = []
  for (let i=1; i<firstDay; i++) {
    td.push(<td key={'start'+i}></td>)
  }
  let currentDay = firstDay;
  let w = 1
  let wd = 0;
  for (let i=1;i<=lastDate;i++) {
    let badge = [];
    if (props.dates[i]!=undefined) {
      wd++;
      badge.push(<CalendarDay dates={props.dates[i]} orders={props.orders} key={i} i={i} />)
    }

    td.push(<td key={'d'+i}>{badge}<span className="date">{i}</span></td>)
    currentDay++
    if (currentDay==8) {
      currentDay=1
      tr.push(<tr key={'w'+w}>{td}</tr>)
      w++
      td = []
    }
  }
  if(currentDay<8) {
    while (currentDay<8) {
      td.push(<td key={'end'+currentDay}></td>)
      currentDay++
    }
    tr.push(<tr key={'w'+w}>{td}</tr>)
  }
  return (
    <div>
      <Table bordered className="report-drivers-calendar"><tbody>{tr}</tbody></Table>
      {wd>0 && <div><strong>Всего рабочих дней:</strong> {wd}</div>}
    </div>
  )
}

function CalendarDay (props) {
  let { dates, orders, i } = props
  let [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen);
  let className = ''
  let position = ''

  let res = []

  if (dates['start']!=undefined){
    position = 'start'
    className = 'start'
    res.push(<CalendarActivity position={position} key={'as'+i} className={className} id={dates[position][0]} i={'s'+i} text={orders[dates[position][0]]} />)
  }
  if (dates['end']!=undefined){
    position = 'end'
    className = 'end'
    res.push(<CalendarActivity position={position} key={'ae'+i} className={className} id={dates[position][0]} i={'e'+i} text={orders[dates[position][0]]} />)
    //return <Link target="_blank" to={"/orders/view/"+dates['end'][0]} title={orders[dates['end'][0]]}><Badge color="success" className="end"  id={'tt'+i}>&nbsp;</Badge></Link>
  }
  if (dates['cont']!=undefined){
    position = 'cont'
    className = 'full'
    res.push(<CalendarActivity position={position} key={'ac'+i} className={className} id={dates[position][0]} i={'c'+i} text={orders[dates[position][0]]} />)
    //return <Link target="_blank" to={"/orders/view/"+dates['cont'][0]} title={orders[dates['cont'][0]]}><Badge color="success" className="full">&nbsp;</Badge></Link>
  }
  if (dates['full']!=undefined){
    position = 'full'
    className = 'start-end'
    res.push(<CalendarActivity position={position} key={'af'+i} className={className} id={dates[position][0]} i={'f'+i} text={orders[dates[position][0]]} />)
    //return <Link target="_blank" to={"/orders/view/"+dates['full'][0]} title={orders[dates['full'][0]]}><Badge color="success" className="start-end">&nbsp;</Badge></Link>
  }
  if (res.length>0) {
    return res
  } else {
    return false
  }
}

function CalendarActivity (props) {
  let { className, position, id, i, text} = props
  let [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Link target="_blank" to={"/orders/view/"+id}>
      <Badge color="success" className={className} id={'tt'+i}>&nbsp;</Badge>
      <Tooltip target={'tt'+i} isOpen={tooltipOpen} toggle={toggle}>{text}</Tooltip>
    </Link>
  )
}

export default ReportDriversCalendar
