import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import {
  Col,
  Nav,
  NavItem,
  NavLink } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SideBar extends Component {

  render () {
    let cLoc = window.location.pathname.substring(1);
    if (cLoc.indexOf('/')>0){
      cLoc = cLoc.substring(0,cLoc.indexOf('/'))
    }
    return (
      <Col xs="2" >
        <div className="sidebar bg-light">
        <Nav vertical className="bg-light">
          <NavItem active={cLoc==='orders'}>
            <NavLink tag={Link} to='/orders'><FontAwesomeIcon icon="route" /> Заявки</NavLink>
          </NavItem>
          <NavItem active={cLoc==='reports'}>
            <NavLink tag={Link} to='/reports'><FontAwesomeIcon icon="chart-line" /> Отчеты</NavLink>
          </NavItem>
          <NavItem active={cLoc==='contractors'}>
            <NavLink tag={Link} to='/contractors'><FontAwesomeIcon icon="handshake" /> Контрагенты</NavLink>
          </NavItem>
          <NavItem active={cLoc==='fleet'}>
            <NavLink tag={Link} to='/fleet'><FontAwesomeIcon icon="truck" /> Парк ТС</NavLink>
          </NavItem>
          <NavItem active={cLoc==='drivers'}>
            <NavLink tag={Link} to='/drivers'><FontAwesomeIcon icon="id-card" /> Водители</NavLink>
          </NavItem>
        </Nav>
        </div>
      </Col>
    )
  }
}

export default SideBar
