import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class LoadingSpinner extends Component {
  render(){
    return (
      <div className="data-loader">
        <div>
          <FontAwesomeIcon icon="spinner" spin /> Загрузка
        </div>
      </div>
    )
  }
}

export default LoadingSpinner
