import React, { Component } from 'react'
import WithAuth from './Login/WithAuth'
import ApiService from './ApiService'
import Helpers from './Helpers'
import {
  Badge,
  Table } from 'reactstrap'

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.API = new ApiService();
    this.state = {
      dataLoaded: false,
      orders_dates: [],
      min_date: 0,
      max_date: 0,
      noe_date: 0,
      orders: [],
      drivers: []
    }
  }

  componentDidMount () {
    this.API.get('/endpoint/dashboard')
      .then(orders=>{
        let dates = [];
        let i = orders.now_date;
        while ((orders.max_date-i)/86400>-1) {
          let d = new Date(i*1000)
          let m = d.getMonth()+1;
          if (m.toString().length<2) m = '0'+m;
          let day = d.getDate()
          if (day.toString().length<2) day = '0'+day
          dates.push(day+'.'+m)
          i+=86400
        }

        this.setState({
          orders: orders.orders,
          drivers: Helpers.JSON2Array(orders.drivers),
          orders_dates: dates,
          min_date: orders.min_date,
          max_date: orders.max_date,
          now_date: orders.now_date,
          dataLoaded: true
        })
      })
  }

  render() {
    let ordersCDay = new Date(this.state.now_date);
    ordersCDay = ordersCDay.getDay()
    let widthGrid = [];
    let gridWidth = 85/(this.state.orders_dates.length*2)
    while (widthGrid.length/2<this.state.orders_dates.length) {
      widthGrid.push(<td key={widthGrid.length+1} width={gridWidth+'%'}></td>)
    }
    return (
      <div>
        <h4>Текущие и запланированые рейсы</h4>
        <div style={{overflow: 'scroll'}}>
        <Table className="dashboard-trips">
          <thead>
            <tr>
              <th width="15%"></th>
              {this.state.orders_dates.map((date)=>
              <th colSpan="2" key={date}>{date}</th>
            )}
            </tr>
          </thead>
          <tbody>
            {this.state.drivers.map((driver, id)=>{
              var cell = 0
              let date = 0;
              return (<tr key={"driver"+id}>
              <th>{driver}</th>
              {this.state.orders[id].orders.map((order,i)=>{
                if (order.finish_date<this.state.now_date)
                  return
                date = Math.floor(cell/2);
                let preTD = [];
                let postTD = '';
                let startDate = (order.start_date>=this.state.now_date) ? new Date(order.start_date*1000) :  new Date(this.state.now_date*1000);
                let startDay = startDate.getDate();
                let startMonth = startDate.getMonth()+1;
                if (startDay.toString().length==1)
                  startDay='0'+startDay;
                if (startMonth.toString().length==1)
                  startMonth='0'+startMonth;
                startDate = startDay+'.'+startMonth;
                while (startDate!=this.state.orders_dates[date]) {
                  cell++
                  date = Math.floor(cell/2);
                  preTD.push(<td></td>)
                  if (this.state.orders_dates[date] === undefined)
                    break;
                }
                let cs=this.calculeteCS(order);
                let color = order.status=='process' ? 'success' : 'warning'
                let style = order.startedBefore ? 'trip-end' : ''
                if (order.start_date==this.state.now_date && cell==0) {
                  preTD.push(<td key={Math.random()}></td>)
                  cell++;
                }
                cell++
                if (this.state.orders[id].orders[i+1]==undefined) {
                  postTD = <td colSpan={this.state.orders_dates.length*2-cell}></td>
                }

                return (
                  <React.Fragment key={'order'+order.id}>
                  {preTD}
                  <td colSpan={cs} >
                    <Badge color={color} className={style}>{order.routeStart+' - '+order.routeFinish}<br/>{order.contractor}, {order.routeCargo}, {(order.routeWeight/1000).toFixed(1)}т.</Badge>
                  </td>
                  {postTD}
                  </React.Fragment>)
              })}
            </tr>)
          })}
          </tbody>
          <tfoot>
            <tr>
              <td></td>{widthGrid}
            </tr>
          </tfoot>
        </Table>
        </div>
      </div>
    )
  }

  daymonth (date) {

  }

  calculeteCS (order) {
    let cs = 0
    if (order.start_date>=this.state.now_date)
      cs = (order.finish_date-order.start_date)/86400*2
    else
      cs = (order.finish_date-this.state.now_date)/86400*2+1
    return cs
  }
}

export default WithAuth(Dashboard)
