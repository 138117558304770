import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import ApiService from '../ApiService'
import {
  Form,FormGroup,Label,Input,InputGroup,InputGroupAddon,InputGroupText,Button
} from 'reactstrap'
import Select from 'react-select'
import LoadingSpinner from '../LoadingSpinner'

class DriversSave extends Component {
  instanceName = 'drivers'
  constructor (props) {
    super(props);
    this.API = new ApiService();
    this.nodeId = 0;
    this.state = {
      saved: false,
      dataLoaded: false,
      dropdown: [],
      last_name: '',
      patronymic_name: '',
      first_name: '',
      phone_number: '',
      phone_messenger: '',
      telegram_start: '',
      fleet_truck: '',
      fleet_trailer: '',
      _fleet_truck: '',
      _fleet_trailer: ''
    }

  }

  componentWillMount () {
    this.nodeId = (this.props.match.params.nodeId === undefined) ? 0 : this.props.match.params.nodeId;
    if (this.nodeId > 0) {
      this.API.bindDataGet('/endpoint/driver_dds', this.updateState, 'dropdown').then(()=>{
        this.API.bindDataGet('/'+this.instanceName+'/get/'+this.nodeId, this.setExistedData);
      })
    } else {
      this.API.bindDataGet('/endpoint/driver_dds', this.updateState, 'dropdown')
      this.setState({dataLoaded: true})
    }
  }

  render() {
    if (this.state.saved)
    {
      return <Redirect to={{pathname: '/'+this.instanceName, state: {nodeSaved: 'add'}}} />
    }


    return (
      <div>
      <h2>{!this.nodeId ? "Добавить" : "Редактировать"} водителя</h2>
      <Form onSubmit={(this._handleSubmit)}>
        <FormGroup>
          <Label for="txtLastName">Фамилия</Label>
          <Input type="text" value={this.state.last_name} onChange={this._updateState} name="last_name" id="txtLastName" required />
        </FormGroup>
        <FormGroup>
          <Label for="txtFirstName">Имя</Label>
          <Input type="text" value={this.state.first_name} onChange={this._updateState} name="first_name" id="txtFirstName" required />
        </FormGroup>
        <FormGroup>
          <Label for="txtPatronymicName">Отчество</Label>
          <Input type="text" name="patronymic_name" value={this.state.patronymic_name} onChange={this._updateState} id="txtPatronymicName" required />
        </FormGroup>
        <FormGroup>
          <Label for="slctVehicle">Авто</Label>
          <Select  name="_fleet_truck" id="slctTrailer" value={this.state._fleet_truck} options={this.state.dropdown.truck} onChange={(v) => this._updateDropdown('fleet_truck', v)}  placeholder="Выберите..." />
        </FormGroup>
        <FormGroup>
          <Label for="slctTrailer">Прицеп</Label>
          <Select  name="_fleet_trailer" id="slctTrailer" value={this.state._fleet_trailer} options={this.state.dropdown.trailer} onChange={(v) => this._updateDropdown('fleet_trailer', v)}  placeholder="Выберите..." />
        </FormGroup>
        <FormGroup>
          <Label for="txtPhoneNumber">Номер телефона</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend"><InputGroupText>+</InputGroupText></InputGroupAddon>
            <Input type="text" name="phone_number" value={this.state.phone_number} onChange={this._updateState} id="txtPhoneNumber" />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label for="txtPhoneMsg">Номер телефона Telegram <span>(не заполнять если совпадает с предидущим)</span></Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend"><InputGroupText>+</InputGroupText></InputGroupAddon>
            <Input type="number" name="phone_messenger" value={this.state.phone_messenger} onChange={this._updateState} id="txtPhoneMsg" />
          </InputGroup>
        </FormGroup>
        {this.state.telegram_start !=='' && <FormGroup>
          <Label for="txtTLink">Ссылка для регистриции у Телеграм-бота</Label>
          <Input type="text" value={'https://t.me/comforttmsdbot?start='+this.state.telegram_start} readOnly onClick={this.copyLink} id="txtPatronymicName" required />
        </FormGroup>}
        <Button color="primary">Сохранить</Button>{'  '}
        <Button tag={Link} to={"/"+this.instanceName}>Отмена</Button>
      </Form>
      {!this.state.dataLoaded ? <LoadingSpinner /> : null}
      </div>
    )
  }

  setExistedData = (data) => {
    for (var key in data) {
      let value = '';
      if (this.state[key]!==undefined) {
        value = data[key]===null ? '' : data[key]
        this.setState({[key]: value})
        switch (key) {
          case 'fleet_truck':
            this.updateState('_fleet_truck', this.findInObject(this.state.dropdown.truck, 'value', value))
            break;
          case 'fleet_trailer':
            this.updateState('_fleet_trailer', this.findInObject(this.state.dropdown.trailer, 'value', value))
            break;
          default:
        }
      }
    }
    this.setState({dataLoaded: true})
  }

  updateState = (key, value) => {
    if (value !== undefined) {
      this.setState({[key]: value});
    } else {
      for (var k in key) {
        if (this.state[k]!==undefined) {
          this.setState({[k]: key[k]})
        }
      }
      this.setState({dataLoaded: true})
    }
  }

  findInObject = (obj, key, value) => {
    for (var i in obj){
      if (obj[i][key] === value) return obj[i];
    }
    return {value: '', label: ''}
  }

  copyLink = (e) => {
    //e.preventDefault();
    let link = e.target.value
    let target = e.target
    target.select();
    target.setSelectionRange(0, 99999);
    document.execCommand("copy");
    target.value = 'Скопировано...'
    setTimeout(()=>{target.value=link},1000)

  }

  _updateState = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  _updateDropdown = (n, v) =>{
    this.updateState(n, v.value)
    this.updateState('_'+n, v)

  }

  _handleSubmit = (e) => {
    e.preventDefault();
    const data2submit = this.state
    delete data2submit.dataLoaded
    delete data2submit.saved
    delete data2submit.dropdown
    delete data2submit._fleet_truck
    delete data2submit._fleet_trailer
    if (this.nodeId>0) {
      this.API.put('/'+this.instanceName+'/save/'+this.nodeId, data2submit)
        .then((response) => {
          if (response.id){
            this.setState({saved: true})
          }
        })
    } else {
      this.API.post('/'+this.instanceName+'/save', data2submit)
        .then((response) => {
          if (response.id){
            this.setState({saved: true})
          }
        })
    }
  }
}

export default DriversSave
