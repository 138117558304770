import React, { Component } from 'react'
import {AuthMessages} from '../../messages'
import AuthService from './AuthService';
import {
  Form,
  Input,
  Button,
  Alert
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.css'

class Login extends Component {
  state = {
    email: '',
    password: '',
    authError: '',
    isLoading: false
  }

  constructor () {
    super();
    this.Auth = new AuthService();
    this._auth = this._auth.bind(this);
  }

  componentWillMount () {
    if (this.props.match.path==='/logout')
    {
      this.Auth.logout();
      this.props.history.replace('/login');
    }
    if(this.Auth.loggedIn())
        this.props.history.replace('/');
    document.body.classList.add('login-page');
  }

  componentWillUnmount () {
    document.body.classList.remove('login-page');
  }

  render () {
    return (
      <div>
        <Form onSubmit={this._auth}>
          <img src="/img/logo-big.png" alt="Comfort TMS" />
          <Input type="email" name="email" onChange={this._updateState} value={this.state.email} placeholder="Email" />
          <Input type="password" name="password" onChange={this._updateState} value={this.state.password} placeholder="Пароль" />
          <Alert color="danger" isOpen={this.state.authError!==""}>{this.state.authError}</Alert>
          <Button color="primary" size="lg" disabled={this.state.isLoading}>{this.state.isLoading && <FontAwesomeIcon icon="spinner" spin={true}/>} Войти</Button>
        </Form>
      </div>
    )
  }

  _updateState = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  _auth = (e) => {
    e.preventDefault();

    this.Auth.login(this.state.email,this.state.password)
      .then(res =>{
        if (!res.error) {
          this.props.history.replace('/');
        } else {
          this.setState({authError: AuthMessages[res.message]})
        }


      })
      .catch(err =>{
        if (!AuthMessages[err.message]){
          err.message = 'network_error'
        }
        this.setState({authError: AuthMessages[err.message]})
      })
  }

}

export default Login
