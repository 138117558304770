import React, { Component } from 'react'
import {VehicleTypes} from '../../params'
import WithAuth from '../Login/WithAuth'
import ApiService from '../ApiService'
import { Link } from 'react-router-dom'
import {
  Badge,
  Button,
  ButtonGroup,
  Alert,
  Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from '../LoadingSpinner'
import Helpers from '../Helpers'


class Fleet extends Component {
  constructor(props) {
    super(props);
    this.API = new ApiService();
    this.state = {
      successAlert: false,
      dataLoaded: false,
      fleet_list: []
    }
  }

  componentWillMount () {
    this.list();
  }

  render() {
    var vehicleTypesDescription = [];
    for (var vehicleType in VehicleTypes){
      vehicleTypesDescription.push(<div key={vehicleType}><Badge color="success">{VehicleTypes[vehicleType].short}</Badge> - {VehicleTypes[vehicleType].full}</div>)
    }
    return (
      <div>
        <Alert className="tmp-alert" isOpen={this.state.successAlert} color="success">ТС сохранено</Alert>
        <h3><FontAwesomeIcon icon="truck" /> Парк ТС</h3>
        <Button className="float-right m-2" color="primary" tag={Link} to="/fleet/save"><FontAwesomeIcon icon="plus" /></Button>
        <Table striped className="mt-1 left table-first-col-thin items-list">
        <thead>
          <tr>
            <th>{ }</th>
            <th>Рег. номер</th>
            <th>Марка, модель, год</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {this.state.fleet_list.map((node) =>
        <tr key={"fleet"+node.id}>
          <td><Badge color="success pill">{VehicleTypes[node.type].short}</Badge></td>
          <td>{node.licence_plate}</td>
          <td>{node.make} {node.model} ({node.production_year})</td>
          <td>
            <ButtonGroup>
              <Button color="primary" size="sm" tag={Link} to={"/fleet/save/"+node.id}><FontAwesomeIcon icon="pen" /></Button>
              <Button color="danger" size="sm" node-id={node.id} title="Удалить" onClick={this.deleteNode}><FontAwesomeIcon icon="trash" /></Button>
            </ButtonGroup>
          </td>
        </tr>
      )}
      </tbody>
    </Table>
    {vehicleTypesDescription}
    {!this.state.dataLoaded ? <LoadingSpinner /> : null}
    </div>
    )
  }

  list() {
    this.API.get('/fleet')
      .then(fleet_list=>{
        this.setState({
          fleet_list,
          dataLoaded: true
        })
      })
  }

  deleteNode = (e) => {
    const nodeId = Number(Helpers.findAttribute(e.target, 'node-id'))
    const fleet_list = this.state.fleet_list;
    let nodeIndex = null
    let node = fleet_list.find((element, i) => {
      if (element.id === nodeId) {
        nodeIndex = i
        return element
      } else {
        return false
      }
    })
    let msg = 'Удалить '+node.make+' ('+node.licence_plate+')?'
    if (window.confirm(msg)) {
      this.API.delete('/fleet/'+nodeId)
        .then((response) => {
          if (response){
            fleet_list.splice(nodeIndex, 1);
            this.setState({fleet_list})
          }
      })
    }
  }
}

export default WithAuth(Fleet)
