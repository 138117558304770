import React, { Component } from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('HAS console.error();');
        this.setState({
          hasError: true,
          error: error,
          errorInfo: errorInfo
        });
      }

  render() {
    if (this.state.hasError) {
      return <h1>Что-то пошло не так.</h1>;
    }


    return this.props.children;
  }
}

export default ErrorBoundary
