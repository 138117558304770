import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AuthService from './Login/AuthService'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.Auth = new AuthService();
    this.state = {
      isOpen: false,
      user: this.Auth.getProfile()
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
        <Navbar color="info" dark expand="md">
          <NavbarBrand tag={Link} to="/"><img src="/img/logo-small-white.png" alt="Comfrot TMS" /></NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle nav caret>
                  <FontAwesomeIcon icon="user-circle" /> {this.state.user.name}
                </DropdownToggle>
                <DropdownMenu >
                  <DropdownItem divider />
                  <DropdownItem href="/logout" onClick={this.props.logoutHandler}>
                    <FontAwesomeIcon icon="sign-out-alt" /> Выйти
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
    );
  }
}

export default Header
