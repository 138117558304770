import React, { Component } from 'react'
import ApiService from '../ApiService'
import { Link } from 'react-router-dom'
import {
  Card, CardText, CardBody, CardTitle,
  Button, ButtonGroup,
  Alert,
  Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class VehicleCard extends Component {
  constructor(props){
    super(props)
  }

  render (){
    let vehicle = this.props.data
    if (vehicle.isShown === undefined || vehicle.isShown) {
    return (
      <Card className={'type-'+vehicle.type}>
      <div className="badge">
        <FontAwesomeIcon icon={vehicle.type==='tractor' ? 'truck' : vehicle.type} />
      </div>
      <CardBody>
        <CardTitle>
          <strong>{vehicle.licence_plate}</strong>
          <span>{vehicle.mileage>0?vehicle.mileage+' км':''}</span>
        </CardTitle>
        <Table size="sm">
          <tbody>
            <tr className="table-warning">
              <td>Страховка</td>
              <td>04.02.2020</td>
            </tr>
            <tr>
              <td>Техсомотр</td>
              <td>23.09.2020</td>
            </tr>
          </tbody>
        </Table>
        <Table size="sm">
          <tbody>
            <tr>
              <td>Масло</td>
              <td>248734 км</td>
            </tr>
            <tr className="table-danger">
              <td>Топлив. фильтр</td>
              <td>234564 км</td>
            </tr>
          </tbody>
        </Table>
        <Button color="primary" size="sm" tag={Link} to={"/maintenance/save/"+vehicle.id} title=""><FontAwesomeIcon icon="wrench" /> Ремонт/Осблуживание</Button>
      </CardBody>
      </Card>)
    } else {
      return '';
    }
  }
}

export default VehicleCard
