import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Form,FormGroup,Label,Input,InputGroup,Button,
  Col, Row,
  Table,
  Badge,
  Tooltip
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ApiService from '../ApiService'
import LoadingSpinner from '../LoadingSpinner'

class ReportDriversDuty extends Component {
  monthes = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']

  state = {
    dataLoaded: false,
    month: (new Date).getMonth(),
    year: (new Date).getFullYear(),
    drivers: [],
    days: {},
    activities: {},
    orders: {},
    holidays: {}
  }

  saveTimeout = 0

  constructor() {
    super()
    this.API = new ApiService();
    this.getMonth = this.getMonth.bind(this)
    this.updateActivity = this.updateActivity.bind(this)
    this.saveActivity = this.saveActivity.bind(this)
  }

  componentDidMount() {
    this.API.get('/report/drivers_duty')
            .then(data=>{
              this.setState({
                drivers: data.drivers,
                days: data.days,
                activities: data.activities,
                orders: data.orders,
                holidays: data.holidays,
                dataLoaded: true
              })
            })
  }

  render() {
    if (!this.state.dataLoaded) {
      return <LoadingSpinner />
    }
    let years = []
    let cy = new Date().getFullYear()
    for (let i=2020;i<=cy;i++) {
      years.push(<option value={i} key={'y'+i}>{i}</option>)
    }
    return (
      <div>
      <Row>
      <Col lg={5}>
        <FormGroup>
          <Label>Месяц и год</Label>
          <InputGroup>
            <Input type="select" name="month" defaultValue={this.state.month} className="col-lg-6">
            {this.monthes.map((n,m)=>{
              return <option value={m} key={'m'+m}>{n}</option>
            })}
            </Input>
            <Input type="select" name="year" id="year" defaultValue={this.state.year} className="col-lg-6">
            {years}
            </Input>
          </InputGroup>
        </FormGroup>
      </Col>
      <Col lg={2}>
        <FormGroup>
          <Label>&nbsp;</Label><br/>
          <Button color="primary" onClick={this.getMonth}>Показать</Button>
        </FormGroup>
      </Col>
      </Row>
      <DriversDuty drivers={this.state.drivers} days={this.state.days} activities={this.state.activities} orders={this.state.orders} updateActivity={this.updateActivity} holidays={this.state.holidays} mid={'y'+this.state.year+'m'+(this.state.month*1+1)} />
      </div>
    )
  }

  getMonth () {
    let payload = {
      year: document.getElementsByName('year')[0].value,
      month: document.getElementsByName('month')[0].value
    }
    this.setState({
      dataLoaded: false,
      month: payload.month,
      year: payload.year
    })
    this.API.get('/report/drivers_duty', payload)
            .then(data=>{
              this.setState({
                days: data.days,
                activities: data.activities,
                orders: data.orders,
                holidays: data.holidays,
                dataLoaded: true
              })
            })
  }

  updateActivity(e) {
    e.preventDefault()
    clearTimeout(this.saveTimeout)
    let list = {
      driving: 'waiting',
      waiting: 'rest',
      rest: 'trip_start',
      trip_start: 'trip_end',
      trip_end: 'trip_restart',
      trip_restart: 'workshop',
      workshop: 'default',
      default: 'driving'
    }
    let {driver, day} = e.target.closest('a').dataset
    let activities = this.state.activities
    if(activities[driver][day]!==undefined){
      if (activities[driver][day].a == '') activities[driver][day].a = 'default';
      activities[driver][day].a = list[activities[driver][day].a];
      if (activities[driver][day].a=='default') {
        activities[driver][day].a = 0
      }
    } else {
      activities[driver][day] = {a: list.default,o:''}
    }
    this.saveTimeout = setTimeout(()=>{this.saveActivity(day,driver,activities[driver][day].a)}, 2000)
    this.setState({activities})
  }

  saveActivity(day,driver,activity) {
    if (window.confirm('Сохранить изменения?')) {
      if (activity === undefined) activity=0
      let payload = {day,driver,activity}
      this.API.get('/report/drivers_duty_save', payload)
              .then(data=>{

              },
              error=>{
                console.log(error);
              })

    }
  }

  _updateState = (e,b,c) => {
    this.setState({[e.target.name]: e.target.value});
  }
}

function DriversDuty (props) {
  let {days, activities, orders, updateActivity, drivers, holidays,mid} = props
  let dWorkdays = {}
  let dStartCount = {}
  let [allowance, setAllowance] = useState(200);

  return <div>
    <Table size="sm" bordered hover className="report-drivers-duty">
    <thead>
      <tr>
        <th>Водитель</th>
        {Object.entries(days).map((d)=>{
          let hd=holidays[d[0]]!==undefined?'holiday':''
          return <td key={d[0]} className={hd}>{d[1]}</td>
        })}
      </tr>
    </thead>
    <tbody>
    {Object.entries(drivers).map((node)=>{
      let icon = ''
      let color = ''
      let act = ''
      let tooltip = []
      let o = []
      if (dWorkdays['d'+node[1].id] === undefined) {
        dWorkdays['d'+node[1].id]=0;
        dStartCount['d'+node[1].id]=false;
      }

      return <tr key={'dr'+node[1].id} >
        <td>{node[1].name}</td>
        {Object.entries(days).map((d)=>{
          if (d[0].substring(0,d[0].indexOf('d'))==mid) {
            dStartCount['d'+node[1].id]=true
          }
          if (activities[node[1].id]!=undefined && activities[node[1].id][d[0]]!=undefined && activities[node[1].id][d[0]].a!=0){
            switch (activities[node[1].id][d[0]].a) {
              case 'driving':
                icon='truck'
                if (dStartCount['d'+node[1].id]){
                  dWorkdays['d'+node[1].id]++;
                }
                break;
              case 'workshop':
                icon='tools'
                break;
              case 'waiting':
                icon='clock'
                if (dStartCount['d'+node[1].id]) dWorkdays['d'+node[1].id]++;
                break;
              case 'trip_start':
                icon='sign-out-alt'
                dStartCount['d'+node[1].id]=true
                dWorkdays['d'+node[1].id]+=0.5;
                break;
              case 'trip_restart':
                icon='retweet'
                dStartCount['d'+node[1].id]=true
                dWorkdays['d'+node[1].id]++;
                break;
              case 'trip_end':
                icon='sign-in-alt'
                if(d[0].substring(0,d[0].indexOf('d'))!=mid) {
                  dWorkdays['d'+node[1].id] = 0;
                } else if (dStartCount['d'+node[1].id]) dWorkdays['d'+node[1].id]+=0.5
                break;
              default:
                icon='bed'
            }
            color='grey'
            tooltip=[]
            if (activities[node[1].id][d[0]].o!=='' && activities[node[1].id][d[0]].o!==null){
              o=activities[node[1].id][d[0]].o.split(',')
              for(let oid of o){
                tooltip.push(orders[oid])
              }
            }

          } else {
            icon='bed'
            color='lightgrey'
            tooltip=[]
          }

          return <DutyCell key={'d'+node[1].id+''+d[0]} driver={node[1].id} day={d[0]} color={color} icon={icon} callback={updateActivity} tooltip={tooltip} holiday={holidays[d[0]]!==undefined} />
        })}
      </tr>
    })}
    </tbody>
  </Table>
  <h4>Итоги</h4>
  <Form inline>
  <FormGroup>
    <Label for="txtPay">Суточные:</Label>
    <Input type="text" value={allowance} onChange={e => setAllowance(e.target.value)} name="allowance" id="txtPay" />
  </FormGroup>
  </Form>
  <Table size="sm" bordered hover>
  <tbody>
  {Object.entries(drivers).map((node)=>{
    return <tr key={'res'+node[0]}>
      <td>{node[1].name}</td>
      <td>{dWorkdays['d'+node[1].id]}</td>
      <td>{dWorkdays['d'+node[1].id]*allowance}</td>
    </tr>
  })}
  </tbody>
  </Table>
  </div>
}

function DutyCell (props) {
  let {driver,day,color,callback,icon,tooltip,holiday} = props
  let [tooltipOpen, setTooltipOpen] = useState(false)
  let hday = holiday ? 'holiday' : ''
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return <td id={'dr'+driver+''+day} className={hday}>
    <a href="#" style={{color}} data-driver={driver} data-day={day} onClick={callback}>
      <FontAwesomeIcon icon={icon} />
      {tooltip!='' ? <Tooltip target={'dr'+driver+''+day} isOpen={tooltipOpen} toggle={toggle} placement="right"><ul>{tooltip.map((tt,i)=>{return <li key={'tt'+i}>{tt}</li>})}</ul></Tooltip> : ''}
    </a>

  </td>
}


export default ReportDriversDuty
