import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import ApiService from '../ApiService'
//import LardiApi from '../LardiApi'
import {
  Form, FormGroup, InputGroupAddon, InputGroup, Label, Input, FormText,
  Button,
  Row, Col
  } from 'reactstrap'
import LoadingSpinner from '../LoadingSpinner'
import {PaymentTermEvents} from '../../params'

class OrdersSave extends Component {
  constructor (props) {
    super(props);
    this.API = new ApiService();
    //this.Lardi = new LardiApi();

    this.nodeId = 0;

    this.state = {
      saved: false,
      dataLoaded: false,
      dropdown: [],
      status: '',
      paid_on: '',
      routeStart: '',
      routeStartDate: '',
      routeStartInfo: '',
      routeFinish: '',
      routeFinishDate: '',
      routeFinishInfo: '',
      routeCargo: '',
      routeWeight: '',
      vehicle_id: '',
      _vehicle_id: {label: '', value: ''},
      trailer_id: '',
      _trailer_id: {label: '', value: ''},
      driver_id: '',
      _driver_id: {label: '', value: ''},
      contractor_id: '',
      _contractor_id: {label: '', value: ''},
      contractor_order: '',
      suggested_contractor_order: '',
      paymentAmount: '',
      paymentAccount: '',
      paymentTerm: '0',
      paymentTermType: 'bank',
      paymentTermEvent: ''
    }

  }

  componentWillMount () {
    this.nodeId = (this.props.match.params.nodeId === undefined) ? false : this.props.match.params.nodeId;

    if (this.nodeId > 0) {
      this.API.bindDataGet('/endpoint/order_dds', this.updateState, 'dropdown').then(()=>{
        this.API.bindDataGet('/orders/get/'+this.nodeId, this.setExistedData);
      });
    } else {
      this.API.bindDataGet('/endpoint/order_dds', this.updateState, 'dropdown')
      this.setState({dataLoaded: true})
    }
  }

  render() {
    let redirect_to = '/orders';
    if (this.state.status==='closed' && this.state.paid_on===null) {
      redirect_to+='/unpaid';
    }
    if (this.state.saved)
    {
      return <Redirect to={{pathname: redirect_to, state: {nodeSaved: 'add'}}} />
    }

    return (
      <div>
      <h2>{!this.nodeId ? "Новая заявка" : "Заявка №"+this.nodeId}</h2>
      <Form onSubmit={(this._handleSubmit)}>
        <h5>Маршрут</h5>
        <Row>
          <Col lg={4}>
            <FormGroup>
              <Label for="txtRouteStart">Загрузка</Label>
              <Input type="text" value={this.state.routeStart} onChange={this._updateState} name="routeStart" id="txtRouteStart" required />
            </FormGroup>
            <FormGroup>
              <Label for="txtRouteStartDate" className="sr-only">Дата загрузки</Label>
              <Input type="date" value={this.state.routeStartDate} onChange={this._updateState} name="routeStartDate" id="txtRouteStartDate" required />
            </FormGroup>
            <FormGroup>
              <Label for="txtRouteStartInfo">Информация <span>для водителя</span></Label>
              <Input type="textarea" value={this.state.routeStartInfo} onChange={this._updateState} name="routeStartInfo" id="txtRouteStartInfo" />
            </FormGroup>
          </Col>
          <Col lg={4}>
            <FormGroup>
              <Label for="txtRouteStart">Выгрузка</Label>
              <Input type="text" value={this.state.routeFinish} onChange={this._updateState} name="routeFinish" id="txtRouteFinish" required />
            </FormGroup>
            <FormGroup>
              <Label for="txtRouteFinishDate" className="sr-only">Дата выгрузки</Label>
              <Input type="date" value={this.state.routeFinishDate} onChange={this._updateState} name="routeFinishDate" id="txtRouteFinishDate" required />
            </FormGroup>
            <FormGroup>
              <Label for="txtRouteFinishInfo">Информация <span>для водителя</span></Label>
              <Input type="textarea" value={this.state.routeFinishInfo} onChange={this._updateState} name="routeFinishInfo" id="txtRouteFinishInfo" />
            </FormGroup>
          </Col>
          <Col lg={2}>
            <FormGroup>
              <Label for="txtRouteCargo">Груз</Label>
              <Input type="text" value={this.state.routeCargo} onChange={this._updateState} name="routeCargo" id="txtRouteCargo" required />
            </FormGroup>
          </Col>
          <Col lg={2}>
            <FormGroup>
              <Label for="txtRouteWeight">Вес, кг</Label>
              <Input type="number" value={this.state.routeWeight} onChange={this._updateState} name="routeWeight" id="txtRouteWeight" required />
            </FormGroup>
          </Col>
        </Row>

        <h5>Исполнитель</h5>
        <Row>
          <Col lg={4}>
            <FormGroup>
              <Label for="slctDriver">Водитель</Label>
              <Select  name="_driver_id" id="slctDriver" value={this.state._driver_id} options={this.state.dropdown.drivers} onChange={(v) => this._updateDropdown('driver_id', v)}  placeholder="Выберите..." />
            </FormGroup>
          </Col>
          <Col lg={4}>
            <FormGroup>
              <Label for="slctVehicle">ТС</Label>
              <Select  name="_vehicle_id" id="slctTrailer" value={this.state._vehicle_id} options={this.state.dropdown.truck} onChange={(v) => this._updateDropdown('vehicle_id', v)}  placeholder="Выберите..." />
            </FormGroup>
          </Col>
          <Col lg={4}>
            <FormGroup>
              <Label for="slctTrailer">Прицеп</Label>
              <Select  name="_trailer_id" id="slctTrailer" value={this.state._trailer_id} options={this.state.dropdown.trailer} onChange={(v) => this._updateDropdown('trailer_id', v)}  placeholder="Выберите..." />
            </FormGroup>
          </Col>
        </Row>
        <h5>Заказчик</h5>
        <Row>
          <Col lg={12}>
            <FormGroup>
              <Select value={this.state._contractor_id} name="contractor_id" options={this.state.dropdown.contractors} onChange={(v) => this._updateDropdown('contractor_id', v)} placeholder="Выберите..." />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
          <FormGroup>
            <Label for="txtContractorOrder">Заявка у заказчика</Label>
            <Input type="text" value={this.state.contractor_order} onChange={this._updateState} name="contractor_order" id="txtContractorOrder" />
            {this.state.suggested_contractor_order!='' ? <FormText><a href="#" onClick={this.useContractorOrder}>{this.state.suggested_contractor_order}</a></FormText> : ''}
          </FormGroup>
          </Col>
        </Row>


        <h5>Оплата</h5>
        <Row>
          <Col lg={4}>
            <FormGroup>
              <Label for="txtPaymentAmount">Ставка</Label>
              <Input type="text" value={this.state.paymentAmount} onChange={this._updateState} name="paymentAmount" id="txtPaymentAmount" required />
            </FormGroup>
          </Col>
          <Col lg={4}>
            <FormGroup>
              <Label for="slctPaymentRecipient">Получатель</Label>
              <Input type="select" value={this.state.paymentAccount} onChange={this._updateState} name="paymentAccount" id="slctPaymentAccount" required>
                <option value=""></option>
                {this.state.dropdown.accounts && Object.keys(this.state.dropdown.accounts).map(item_id =>
                   <option value={item_id} key={"ac"+item_id}>{this.state.dropdown.accounts[item_id]}</option>
               )}
              </Input>
            </FormGroup>
          </Col>
          <Col lg={2}>
            <Label for="txtPaymentTerm">Срок, дн.</Label>
            <InputGroup>

              <Input type="number" className="mb-1" value={this.state.paymentTerm} onChange={this._updateState} name="paymentTerm" id="txtPaymentTerm" required />
              <InputGroupAddon addonType="append">
                <Input type="select" value={this.state.paymentTermType} onChange={this._updateState} name="paymentTermType" id="slctPaymentTermType" required>
                  <option value="bank">Б/Д</option>
                  <option value="calendar">К/Д</option>
                </Input>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg={2}>
            <FormGroup>
              <Label for="slctPaymentTermEvent">По</Label>
              <Input type="select" value={this.state.paymentTermEvent} onChange={this._updateState} name="paymentTermEvent" id="slctPaymentTermEvent" required>
                <option value=""></option>
                {Object.keys(PaymentTermEvents).map(item_id =>
                   <option value={item_id} key={"pt"+item_id}>{PaymentTermEvents[item_id]}</option>
               )}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Button color="primary">Сохранить</Button>{'  '}
        <Button tag={Link} to={redirect_to}>Отмена</Button>
      </Form>
      {!this.state.dataLoaded ? <LoadingSpinner /> : null}
      </div>
    )
  }

  _updateState = (e,b,c) => {
    var specUpdateFunction = 'specUpdate'+e.target.name.charAt(0).toUpperCase() + e.target.name.slice(1);
    if (typeof this[specUpdateFunction] === 'function'){
      this[specUpdateFunction](e);
    }
    this.setState({[e.target.name]: e.target.value});
  }

  _updateDropdown = (n, v) =>{
    if (n === 'driver_id') this.specUpdateVehicle(v)
    if (n === 'contractor_id') this.suggestContractorOrder(v.value)
    this.updateState(n, v.value)
    this.updateState('_'+n, v)
  }

  updateState = (key, value) => {
    if (value !== undefined) {
      this.setState({[key]: value});
    } else {
      for (var k in key) {
        if (this.state[k]!==undefined) {
          this.setState({[k]: key[k]})
        }
      }
      this.setState({dataLoaded: true})
    }
  }

  setExistedData = (data) => {
    for (var key in data) {
      if (this.state[key]!==undefined) {
        this.setState({[key]: data[key]})
        switch (key) {
          case 'vehicle_id':
            this.updateState('_vehicle_id', this.findInObject(this.state.dropdown.truck, 'value', data[key]))
            break;
          case 'trailer_id':
            this.updateState('_trailer_id', this.findInObject(this.state.dropdown.trailer, 'value', data[key]))
            break;
          case 'driver_id':
            this.updateState('_driver_id', this.findInObject(this.state.dropdown.drivers, 'value', data[key]))
            break;
          case 'contractor_id':
            this.updateState('_contractor_id', this.findInObject(this.state.dropdown.contractors, 'value', data[key]))
            break;
          default:
        }
      }
    }
    this.setState({dataLoaded: true})
  }

  specUpdateVehicle = (driver) => {
    let truck = this.findInObject(this.state.dropdown.truck, 'driver_id', driver.value)
    let trailer = this.findInObject(this.state.dropdown.trailer, 'driver_id', driver.value)
    if (trailer.value > 0) {
      this.updateState('trailer_id', trailer.value)
      this.updateState('_trailer_id', trailer)
    }
    if (truck.value > 0) {
      this.updateState('vehicle_id', truck.value)
      this.updateState('_vehicle_id', truck)
    }
    /*
    if (sVehicle.driver_id > 0) {
      this.updateState('driver_id', sVehicle.driver_id)
      this.updateState('_driver_id', this.findInObject(this.state.dropdown.drivers, 'value', sVehicle.driver_id))
    }*/
  }

  findInObject = (obj, key, value) => {
    for (var i in obj){
      if (obj[i][key] === value) return obj[i];
    }
    return {value: '', label: ''}
  }

  _handleSubmit = (e) => {
    e.preventDefault();
    const data2submit = {...this.state}
    delete data2submit.dropdown
    delete data2submit._driver_id
    delete data2submit._trailer_id
    delete data2submit._vehicle_id
    delete data2submit._contractor_id
    delete data2submit.saved
    if(data2submit.contractor_id===''){
      alert('Укажите заказчика!')
      return;
    }
    if(data2submit.vehicle_id===''){
      alert('Укажите ТС!')
      return;
    }
    if(data2submit.driver_id===''){
      alert('Укажите водителя!')
      return;
    }
    if (this.nodeId>0) {
      this.API.put('/orders/save/'+this.nodeId, data2submit)
        .then((response) => {
          if (response.id){
            this.updateState('saved', true)
          }
        })
    } else {
      this.API.post('/orders/save', data2submit)
        .then((response) => {
          if (response.id){
            this.updateState('saved', true)
          }
        })
    }
  }

  suggestContractorOrder = (contractor_id) => {
    this.API.get('/orders/suggest_contractor_order/'+contractor_id)
            .then((response) => {
              if (response != '') {
                this.updateState('suggested_contractor_order', response)
              }
            })
  }

  useContractorOrder = (e) => {
    e.preventDefault();
    this.updateState({
      'contractor_order': this.state.suggested_contractor_order,
      'suggested_contractor_order': ''
    })
  }

  loadCities = (searchText, callback) => {
    if (searchText.length>=3) {
      setTimeout(() => {
        this.Lardi.getCities(searchText, callback)
      }, 1000)
    }
  }
}

export default OrdersSave
