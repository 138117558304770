import React from 'react'

class Helpers {

  static formatDate (date, as_object = false) {
    if (date === null || date === undefined) return '';
    let Y = date.substring(0,4)
    let M = date.substring(5,7)
    let D = date.substring(8,10)
    if (as_object) {
      return new Date(date);
    } else {
      return D+'.'+M+'.'+Y
    }
  }

  static clickGo (path) {
    this.props.history.push(path)
  }

  static updateState(e) {
    let name = e.target.name
    if (name.indexOf('__ac')>0) {
      name = name.substring(0,name.indexOf('__ac'))
    }
    this.setState({[name]: e.target.value});
    const specialMethod = 'update'+name.charAt(0).toUpperCase() + name.slice(1)+'State'

    if (typeof this[specialMethod] === 'function') {
      this[specialMethod](e)
    }
  }

  static findInObject (obj, key, value) {
    for (let i in obj){
      if (obj[i][key] === value) return obj[i];
    }
    return {value: '', label: ''}
  }

  static findAttribute (obj, attr) {
    let attr_value = obj.getAttribute(attr)
    if (attr_value!==null){
      return attr_value
    } else {
      return this.findAttribute(obj.parentNode, attr)
    }
  }

  static buildSelectOptions (o) {
    const Options = []
    Options.push(<option value="" key="0">...</option>)
    for (var key in o){
      Options.push(<option value={key} key={key}>{o[key]}</option>)
    }
    return Options
  }

  static cloneObject (o) {
    const clone = {}
    for (var key in o) {
      clone[key] = o[key]
    }
    return clone
  }

  static getRndInteger(min, max) {
    if (typeof min !== "number") min = 0
    if (typeof min !== "number") min = 10000
    return Math.floor(Math.random() * (max - min) ) + min;
  }

  static autocompleteOff(fieldName) {
    fieldName += '__ac'+this.randomString(6)
    return fieldName
  }

  static randomString(length) {
    let result = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static JSON2Array(json_data)
  {
    let result = []
    for(var i in json_data) {
      result[i]=json_data[i];
    }

    return result
  }

   static amountAsWord(amount) {
       var mapNumbers = {
         0 : [2, 1, "нуль"],
         1 : [0, 2, "один", "одна"],
         2 : [1, 2, "два", "дві"],
         3 : [1, 1, "три"],
         4 : [1, 1, "чотири"],
         5 : [2, 1, "п'ять"],
         6 : [2, 1, "шість"],
         7 : [2, 1, "сім"],
         8 : [2, 1, "вісім"],
         9 : [2, 1, "дев'ять"],
         10 : [2, 1, "десять"],
         11 : [2, 1, "одинадцять"],
         12 : [2, 1, "дванадцять"],
         13 : [2, 1, "тринадцять"],
         14 : [2, 1, "чотирнадцять"],
         15 : [2, 1, "п'ятнадцять"],
         16 : [2, 1, "шістнадцять"],
         17 : [2, 1, "сімнадцять"],
         18 : [2, 1, "вісімнадцять"],
         19 : [2, 1, "дев'ятнадцять"],
         20 : [2, 1, "двадцять"],
         30 : [2, 1, "тридцять"],
         40 : [2, 1, "сорок"],
         50 : [2, 1, "п'ятдесят"],
         60 : [2, 1, "шістдесят"],
         70 : [2, 1, "сімдесят"],
         80 : [2, 1, "вісімдесят"],
         90 : [2, 1, "дев'яносто"],
         100 : [2, 1, "сто"],
         200 : [2, 1, "двісті"],
         300 : [2, 1, "триста"],
         400 : [2, 1, "чотириста"],
         500 : [2, 1, "п'ятсот"],
         600 : [2, 1, "шістсот"],
         700 : [2, 1, "сімсот"],
         800 : [2, 1, "вісімсот"],
         900 : [2, 1, "дев'ятсот"]
  };

  var mapOrders = [
      { _Gender : false, _arrStates : ["гривня", "гривні", "гривень"],},
      { _Gender : false, _arrStates : ["тисяча", "тисячі", "тисяч"] },
      { _Gender : true, _arrStates : ["мільйон", "мільйона", "мільйонів"] },
      { _Gender : true, _arrStates : ["мільярд", "мільярда", "мільярдів"] },
      { _Gender : true, _arrStates : ["триліон", "триліона", "триліонів"] }
  ];

  var objKop = { _Gender : false, _arrStates : ["копійка", "копійки", "копійок"] };

  function Value(dVal, bGender) {
      var xVal = mapNumbers[dVal];
      if (xVal[1] === 1) {
          return xVal[2];
      } else {
          return xVal[2 + (bGender ? 0 : 1)];
      }
  }

  function From0To999(fValue, oObjDesc, fnAddNum, fnAddDesc)
  {
      var nCurrState = 2;
      let fCurr = 0;
      if (Math.floor(fValue/100) > 0) {
          fCurr = Math.floor(fValue/100)*100;
          fnAddNum(Value(fCurr, oObjDesc._Gender));
          nCurrState = mapNumbers[fCurr][0];
          fValue -= fCurr;
      }

      if (fValue < 20) {
          if (Math.floor(fValue) > 0) {
              fnAddNum(Value(fValue, oObjDesc._Gender));
              nCurrState = mapNumbers[fValue][0];
          }
      } else {
          fCurr = Math.floor(fValue/10)*10;
          fnAddNum(Value(fCurr, oObjDesc._Gender));
          nCurrState = mapNumbers[fCurr][0];
          fValue -= fCurr;

          if (Math.floor(fValue) > 0) {
              fnAddNum(Value(fValue, oObjDesc._Gender));
              nCurrState = mapNumbers[fValue][0];
          }
      }

      fnAddDesc(oObjDesc._arrStates[nCurrState]);
  }

  function FloatToSamplesInWordsRus(fAmount)
  {
      var fInt = Math.floor(fAmount + 0.005);
      var fDec = Math.floor(((fAmount - fInt) * 100) + 0.5);

      var arrRet = [];
      var arrThousands = [];
      for (; fInt > 0.9999; fInt/=1000) {
          arrThousands.push(Math.floor(fInt % 1000));
      }
      if (arrThousands.length === 0) {
          arrThousands.push(0);
      }

      function PushToRes(strVal) {
          arrRet.push(strVal);
      }

      for (var iSouth = arrThousands.length-1; iSouth >= 0; --iSouth) {
          if (arrThousands[iSouth] === 0) {
              continue;
          }
          From0To999(arrThousands[iSouth], mapOrders[iSouth], PushToRes, PushToRes);
      }

      if (arrThousands[0] === 0) {
          //  Handle zero amount
          if (arrThousands.length === 1) {
              PushToRes(Value(0, mapOrders[0]._Gender));
          }

          var nCurrState = 2;
          PushToRes(mapOrders[0]._arrStates[nCurrState]);
      }

      if (arrRet.length > 0) {
          // Capitalize first letter
          arrRet[0] = arrRet[0].match(/^(.)/)[1].toLocaleUpperCase() + arrRet[0].match(/^.(.*)$/)[1];
      }

      arrRet.push((fDec < 10) ? ("0" + fDec) : ("" + fDec));
      From0To999(fDec, objKop, function() {}, PushToRes);

      return arrRet.join(" ");
    }

    return FloatToSamplesInWordsRus(amount)
   }

}

Date.prototype.daysInMonth = function() {
		return 33 - new Date(this.getFullYear(), this.getMonth(), 33).getDate();
	};

export default Helpers
