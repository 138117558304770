import React, { Component } from 'react'
import {VehicleTypes} from '../../params'
import WithAuth from '../Login/WithAuth'
import ApiService from '../ApiService'
import { Link } from 'react-router-dom'
import {
  Button,
  Input, InputGroup, InputGroupAddon, InputGroupText,
  Alert,
  Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from '../LoadingSpinner'
import VehicleCard from './VehicleCard'


class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.API = new ApiService();
    this.state = {
      successAlert: false,
      dataLoaded: false,
      filter: '',
      filterResult: [],
      mData: []
    }
  }

  componentDidMount () {
    this.initData();
  }

  render() {

    return (
      <div className="maintenance">
        <div className="header">
          <h3><FontAwesomeIcon icon="tools" /> Обслуживание</h3>
          <div className="search-bar">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText><FontAwesomeIcon icon="search" /></InputGroupText>
            </InputGroupAddon>
            <Input value={this.state.filter} onChange={this.filter} />
          </InputGroup>
          </div>
        </div>
        <div className="cards">
        {this.state.mData.map((node) =>[
          <VehicleCard data={node} key={'v'+node.id} />
        ])}
        </div>
    {!this.state.dataLoaded ? <LoadingSpinner /> : null}
    </div>
    )
  }

  initData() {
    this.API.get('/maintenance/dashboard')
      .then(mData=>{
        console.log(mData);
        this.setState({
          mData,
          dataLoaded: true
        })
      })
  }

  filter = (e) => {
    let search = e.target.value;
    let mData = this.state.mData;
    for (var i in mData){
      if (mData[i].licence_plate.toUpperCase().indexOf(search.toUpperCase())>=0){
        mData[i].isShown = true;
      } else {
        mData[i].isShown = false;
      }
    }
    this.setState({
      filter: search,
      mData
    });
  }

  findInObject = (obj, key, value) => {
    for (var i in obj){
      if (obj[i][key] === value) return obj[i];
    }
    return {value: '', label: ''}
  }

}

export default WithAuth(Maintenance)
