import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import ApiService from '../ApiService'
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup
} from 'reactstrap'
import { ContractorTypes } from '../../params'
import LoadingSpinner from '../LoadingSpinner'

class ContactsSave extends Component {
  instanceName = 'contractors'
  constructor (props) {
    super(props);
    this.API = new ApiService();
    this.nodeId = 0;
    this.state = {
      saved: false,
      dataLoaded: false,
      short_name: '',
      full_name: '',
      reg_number: '',
      type: '',
      tax_number: '',
      iban: '',
      bank: '',
      address: '',
      post_address: '',
      signatory_position: 'Директор',
      signatory_name: '',
      signatory_basis: 'Статуту',
      phone_number: '',
      contract_customer_name: 'Замовник',
      contract_contractor_name: 'Виконавець',
    }
  }

  componentWillMount () {
    this.nodeId = (this.props.match.params.nodeId === undefined) ? 0 : this.props.match.params.nodeId;
    if (this.nodeId > 0) {
      this.API.bindDataGet('/'+this.instanceName+'/get/'+this.nodeId, this.setExistedData);
    } else {
      this.setState({dataLoaded: true})
    }
  }

  render() {
    if (this.state.saved)
    {
      return <Redirect to={{pathname: '/'+this.instanceName, state: {nodeSaved: 'add'}}} />
    }


    return (
      <div>
      <h2>{!this.nodeId ? "Добавить" : "Редактировать"} контрагента</h2>
      <Form onSubmit={(this._handleSubmit)}>
        <FormGroup>
          <Label for="slctType">Тип</Label><br/>
          <ButtonGroup>
            {Object.keys(ContractorTypes).map(item_id =>
               <Button color="success" onClick={this._updateState} key={"t"+item_id} name="type" value={item_id} active={this.state.type===item_id}>{ContractorTypes[item_id].full}</Button>
            )}
          </ButtonGroup>
        </FormGroup>
        <FormGroup>
          <Label for="txtShortName">Короткое название</Label>
          <Input type="text" value={this.state.short_name} onChange={this._updateState} name="short_name" id="txtShortName" required />
        </FormGroup>
        <FormGroup>
          <Label for="txtFullName">Полное название</Label>
          <Input type="text" value={this.state.full_name} onChange={this._updateState} name="full_name" id="txtFullName" required />
        </FormGroup>
        <FormGroup>
          <Label for="txtRegNumber">ЕГРПОУ</Label>
          <Input type="number" value={this.state.reg_number} onChange={this._updateState} name="reg_number" id="txtRegNumber" />
        </FormGroup>
        <FormGroup>
          <Label for="txtVatNumber">ИНН</Label>
          <Input type="number" value={this.state.tax_number} onChange={this._updateState} name="tax_number" id="txtVatNumber" />
        </FormGroup>
        <FormGroup>
          <Label for="txtCCuname">Заказчик в акте</Label>
          <Input type="text" value={this.state.contract_customer_name} onChange={this._updateState} name="contract_customer_name" id="txtCCuname" />
        </FormGroup>
        <FormGroup>
          <Label for="txtCCName">Исполнитель в акте</Label>
          <Input type="text" value={this.state.contract_contractor_name} onChange={this._updateState} name="contract_contractor_name" id="txtCCName" />
        </FormGroup>
        <FormGroup>
          <Label for="txtIBAN">IBAN</Label>
          <Input type="text" value={this.state.iban} onChange={this._updateState} name="iban" id="txtIBAN" />
        </FormGroup>
        <FormGroup>
          <Label for="txtBank">Банк</Label>
          <Input type="text" value={this.state.bank} onChange={this._updateState} name="bank" id="txtBank" />
        </FormGroup>
        <FormGroup>
          <Label for="txtAddress">Адрес</Label>
          <Input type="text" value={this.state.address} onChange={this._updateState} name="address" id="txtAddress" />
        </FormGroup>
        <FormGroup>
          <Label for="txtPAddress">Почтовый адрес</Label>
          <Input type="text" value={this.state.post_address} onChange={this._updateState} name="post_address" id="txtPAddress" />
        </FormGroup>
        <FormGroup>
          <Label for="txtPhone">Телефон</Label>
          <Input type="text" value={this.state.phone_number} onChange={this._updateState} name="phone_number" id="txtPhone" />
        </FormGroup>
        <FormGroup>
          <Label for="txtSignName">ФИО подписанта</Label>
          <Input type="text" value={this.state.signatory_name} onChange={this._updateState} name="signatory_name" id="txtSignName" />
        </FormGroup>
        <FormGroup>
          <Label for="txtSignPos">Должность подписанта</Label>
          <Input type="text" value={this.state.signatory_position} onChange={this._updateState} name="signatory_position" id="txtSignPos" />
        </FormGroup>
        <FormGroup>
          <Label for="txtSignBasis">Действует на основании</Label>
          <Input type="text" value={this.state.signatory_basis} onChange={this._updateState} name="signatory_basis" id="txtSignBasis" />
        </FormGroup>
        <Button color="primary">Сохранить</Button>{'  '}
        <Button tag={Link} to={"/"+this.instanceName}>Отмена</Button>
      </Form>
      {!this.state.dataLoaded ? <LoadingSpinner /> : null}
      </div>
    )
  }

  setExistedData = (data) => {
    for (var key in data) {
      let value = '';
      if (this.state[key]!==undefined) {
        value = data[key]===null ? '' : data[key]
        this.setState({[key]: value})
      }
    }
    this.setState({dataLoaded: true})
  }

  updateState = (key, value) => {
    if (value !== undefined) {
      this.setState({[key]: value});
    } else {
      for (var k in key) {
        if (this.state[k]!==undefined) {
          this.setState({[k]: key[k]})
        }
      }
      this.setState({dataLoaded: true})
    }
  }

  _updateState = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  _handleSubmit = (e) => {
    e.preventDefault();
    const data2submit = this.state
    delete data2submit.dataLoaded
    delete data2submit.saved
    if (this.nodeId>0) {
      this.API.put('/'+this.instanceName+'/save/'+this.nodeId, data2submit)
        .then((response) => {
          if (response.id){
            this.setState({saved: true})
          }
        })
    } else {
      this.API.post('/'+this.instanceName+'/save', data2submit)
        .then((response) => {
          if (response.id){
            this.setState({saved: true})
          }
        })
    }
  }
}

export default ContactsSave
