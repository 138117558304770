import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ApiService from '../ApiService'
import {
  FormGroup, Label,Form,Input,InputGroup,InputGroupAddon, CustomInput,
  Button,ButtonGroup,
  Row,Col,
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from '../LoadingSpinner'
import { API_URI, PostServices } from '../../params'
import Helpers from '../Helpers'

class OrdersDocs extends Component {

  constructor (props) {
    super(props);
    this.API = new ApiService();

    this.doctypes = {
      invoice: 'Счет',
      tax: 'Налоговая',
      act: 'Акт'
    }

    this.nodeId = 0

    this.state = {
      dataLoaded: false,
      newDropdownOpen: false,
      newDocModal: {
        doctype: '',
        title: '',
        open: false
      },
      NDMNumber: '',
      NDMDate: '',
      NDMLoading: false,
      NDMSubmiting: false,
      service_name: '',
      service_name_changed: false,
      service_name_saving: false,
      service_name_init: '',
      tax_registred_saving: false,
      postservice_saving: false,
      recieving_docs: false,
      order_data: {
        order: {
          id: '',
          routeStart: '',
          routeStartDate: '',
          routeFinish:'',
          papers_done: 0
        },
        docs: [],
        sent_operator: '',
        sent_date: '',
        sent_number: '',
        tax_registred: ''
      },
      docs_exist: {
        act: false,
        invoice: false,
        tax: false
      }
    }

  }

  componentDidMount () {
    this.nodeId = (this.props.match.params.nodeId === undefined) ? false : this.props.match.params.nodeId;
    this.API.bindDataGet('/orders/docs/'+this.nodeId, this.initData, 'order_data');
  }

  render() {
    let order = this.state.order_data.order;
    let return_link = '/orders';
    if((order.paid_on===null || order.paid_on===undefined) && order.status == 'closed') {
      return_link += '/unpaid';
    }
    return (
      <div>
      <Button tag={Link} to={return_link} outline color="info" size="sm"><FontAwesomeIcon icon="arrow-left" /> К списку заявок</Button>
      <br/><br/>
      <Row>
        <Col lg={6}>
          <h2>Документы по заявке №{order.id}</h2>
          <h6>{Helpers.formatDate(order.routeStartDate)} {order.routeStart} - {order.routeFinish} ({order.vehicle}, {order.driver_lname})</h6>
        </Col>
        <Col lg={6} className="text-right">
          <ButtonDropdown isOpen={this.state.newDropdownOpen} toggle={()=>{this.setState({newDropdownOpen: !this.state.newDropdownOpen})}} className="m-2">
          <DropdownToggle caret color="primary">
            {this.state.NDMLoaing ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="plus"/>}
            &nbsp;Создать документ&nbsp;
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={this.toggleNewDocModal} doctype="invoice" disabled={this.state.docs_exist.invoice}>Счет</DropdownItem>
            <DropdownItem onClick={this.toggleNewDocModal} doctype="act" disabled={this.state.docs_exist.act}>Акт</DropdownItem>
            <DropdownItem onClick={this.toggleNewDocModal} doctype="tax" disabled={this.state.docs_exist.tax}>Налоговая</DropdownItem>
          </DropdownMenu>
          </ButtonDropdown>
        </Col>
      </Row>
      <div>
      <FormGroup>
        <Label for="txtNameForDocs">Наименование услуги</Label>
        <InputGroup>
          <Input type="text" value={this.state.service_name} onChange={this._updateServiceName} name="service_name" id="txtNameForDocs" />
          <InputGroupAddon addonType="append">
            <Button color="primary" disabled={!this.state.service_name_changed} onClick={this.updateServiceName}>{this.state.service_name_saving ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="check" />} Сохранить</Button>
            </InputGroupAddon>
        </InputGroup>
      </FormGroup>
      <div className="docs">
      {this.state.order_data.docs.map((node) =>[
      <div className="page" key={'doc_'+node.doctype}>
        <div>
        <span className="corner">
          <svg height="30" width="30">
           <line x1="0" y1="1" x2="29" y2="30" />
        </svg>
        </span>
        <div>
          <h5>{this.doctypes[node.doctype]}</h5>
          <h6> №{node.number}</h6>
          от {Helpers.formatDate(node.date)}
          <div className="buttons">
            <ButtonGroup>
              {node.doctype==='tax'
                ? <Button color="success" size="sm" title="Скачать XML" href={API_URI+'/print/'+node.doctype+'/'+order.id+'?token='+this.API.Auth.getToken()} target="_blank"><FontAwesomeIcon icon="file-export" /></Button>
                : <Button color="success" size="sm" title="Печать" tag={Link} to={'/orders/print/'+node.doctype+'/'+order.id}><FontAwesomeIcon icon="print" /></Button>}
              <Button color="danger" size="sm" title="Удалить" onClick={this.deleteDoc.bind(this, node.doctype)} doctype={node.doctype}><FontAwesomeIcon icon="trash" /></Button>
            </ButtonGroup>
          </div>
          {node.doctype === 'tax' ?
            <div className="tax-registred">
            <FormGroup>
              <Label for="txtTaxReg">Зарегистрирована</Label>
              <InputGroup>
                <Input type="date" bsSize="sm" value={this.state.order_data.tax_registred} onChange={this._updateState} name="order_data.tax_registred" id="txtTaxReg" />
                <InputGroupAddon addonType="append">
                  <Button size="sm" color="success" onClick={this.updateTaxRegDate}>
                    {this.state.tax_registred_saving ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="check" />}
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            </div>
           : ''}
        </div>
      </div>
      </div>])}
      </div>
      </div>
      <div className="postservice">
        <h4>Информация об отправке:</h4>
        <Form onSubmit={this.savePostservice}>
        <FormGroup>
          <Label for="rdOperator">Оператор</Label><br />
          <ButtonGroup>
            {Object.keys(PostServices).map((key) =>[
            <Button outline onClick={this._updateState} name="order_data.sent_operator" value={key} active={this.state.order_data.sent_operator===key} key={'ps'+key}>{PostServices[key]}</Button>
            ])}
          </ButtonGroup>
        </FormGroup>
        <FormGroup>
          <Label for="txtSentNum">Номер декларации</Label>
          <Input type="text" value={this.state.order_data.sent_number} onChange={this._updateState} name="order_data.sent_number" id="txtSentNum" />
        </FormGroup>
        <FormGroup>
          <Label for="txtSentDate">Дата декларации</Label>
          <Input type="date" value={this.state.order_data.sent_date} onChange={this._updateState} name="order_data.sent_date" id="txtSentDate" />
        </FormGroup>
        <Button color="primary" disabled={this.state.postservice_saving}>
          {this.state.postservice_saving ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="check" />} Сохранить
        </Button>
        <br/><br />
        <FormGroup>
          <CustomInput type="switch" id="papersDone" name="papers_done" checked={this.state.order_data.order.papers_done} label="Наши документы получены" onChange={this.docsRecieved} disabled={this.state.recieving_docs} />
        </FormGroup>
        </Form>
      </div>
      <br/><br/>
      <Button tag={Link} to={return_link} outline color="info" size="sm"><FontAwesomeIcon icon="arrow-left" /> К списку заявок</Button>
      <Modal isOpen={this.state.newDocModal.open}>
        <ModalHeader>{this.state.newDocModal.title}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="txtNDMNumber">Номер</Label>
            <Input type="text" value={this.state.NDMNumber} name="NDMNumber" onChange={this._updateState} id="txtNDMNumber" />
          </FormGroup>
          <FormGroup>
            <Label for="txtNDMDate">Дата</Label>
            <Input type="date" value={this.state.NDMDate} onChange={this._updateState} name="NDMDate" id="txtNDMDate" />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.createDocument}>{this.state.NDMSubmiting ? <FontAwesomeIcon icon="spinner" spin /> : 'Создать'}</Button>{' '}
          <Button color="secondary" onClick={this.toggleNewDocModal}>Отмена</Button>
        </ModalFooter>
      </Modal>
      {!this.state.dataLoaded ? <LoadingSpinner /> : null}
      </div>
    )
  }

  _updateState = (e,b,c) => {
    if (e.target.name.indexOf('.')>=0) {
      let changedProp = e.target.name.split('.')[0];
      let changedPropValue = this.state[changedProp];
      let targetProp = e.target.name.substring(e.target.name.indexOf('.')+1);
      this.updateObjPropByPath(changedPropValue, targetProp, e.target.value)
      this.setState({[changedProp]: changedPropValue});
    } else {
      this.setState({[e.target.name]: e.target.value});
    }
  }

  updateObjPropByPath = (obj, prop, value) => {
    if (typeof prop === "string")
        prop = prop.split(".");

    if (prop.length > 1) {
        var e = prop.shift();
        this.assign(obj[e] =
                 Object.prototype.toString.call(obj[e]) === "[object Object]"
                 ? obj[e]
                 : {},
               prop,
               value);
    } else
        obj[prop[0]] = value;
  }

  initData = (key, value) => {
    if (value.service_name) {
      this.setState({service_name: value.service_name,service_name_init: value.service_name})
    }

    let docs_exist = this.state.docs_exist
    value.docs.map((node) =>{
      docs_exist[node.doctype] = true
      return true
    })
    if (value.sent_date === null) value.sent_date = '';
    if (value.tax_registred === null) value.tax_registred = '';

    this.setState({
      [key]: value,
      docs_exist,
      dataLoaded: true
    })
  }

  _updateServiceName = (e,b,c) => {
    let s = {[e.target.name]: e.target.value}
    if (e.target.value !== this.state.service_name) s.service_name_changed = true
    this.setState(s);
  }

  updateServiceName = () => {
    this.setState({service_name_saving: true})
    this.API.put('/orders/docs/'+this.state.order_data.order.id+'/snupdate', {service_name: this.state.service_name})
      .then((response) => {
        if(response.service_name === this.state.service_name){
          this.setState({
            service_name_saving: false,
            service_name_changed: false
          })
        }
      })
  }

  updateTaxRegDate = () => {
    this.setState({tax_registred_saving: true})
    this.API.put('/orders/docs/'+this.state.order_data.order.id+'/taxregupdate', {tax_registred: this.state.order_data.tax_registred})
      .then((response) => {
        if(response.tax_registred === this.state.order_data.tax_registred){
          this.setState({
            tax_registred_saving: false
          })
        }
      })
  }

  createDocument = () => {
    let order = this.state.order_data.order;
    let service_name = '';
    this.setState({NDMSubmiting: true})
    if (this.state.order_data.docs.length === 0) {
      service_name = 'Автопослуги '+order.routeStart+' - '+order.routeFinish+', а/м '+order.vehicle+'/'+order.trailer
      this.setState({service_name})
    }
    let doc_data = {
      doctype: this.state.newDocModal.doctype,
      number: this.state.NDMNumber,
      date: this.state.NDMDate
    }
    if (service_name!=='')
      doc_data.service_name = service_name
    this.API.post('/orders/docs/'+order.id+'/add', doc_data)
      .then((responseData) => {
        this.initData('order_data', responseData)
        let newDocModal = this.state.newDocModal
        newDocModal.open = false;
        this.setState({
          newDocModal,
          NDMSubmiting: false
        })
      })
  }

  deleteDoc = (doctype) => {
    if (window.confirm('Удалить документ: '+this.doctypes[doctype])) {
      document.body.style.cursor = 'progress'
      this.API.delete('/orders/docs/'+this.state.order_data.order.id+'/'+doctype)
        .then((responseData) => {
          this.initData('order_data', responseData)
          document.body.style.cursor = 'auto'
        })
    }
  }

  toggleNewButton = (s) => {
    console.log(s);
    return !s
  }

  toggleNewDocModal = (e) => {
    let newDocModal = this.state.newDocModal;
    let doctype = e.target.getAttribute('doctype')
    if (doctype!==null){
      this.setState({NDMLoaing: true})
      newDocModal.title = this.doctypes[doctype]
      newDocModal.doctype = doctype
      this.API.get('/orders/docnumber/'+this.state.order_data.order.id+'/'+doctype)
        .then((responseData) => {
          let newDocModal = this.state.newDocModal
          newDocModal.open = true
          this.setState({
            newDocModal,
            NDMNumber: responseData.number,
            NDMDate: responseData.date,
            NDMLoaing: false
          })
        })
    } else {
      newDocModal.open = !newDocModal.open;
      this.setState({newDocModal})
    }
  }

  docsRecieved = (e) => {
    let run_request = false;
    this.setState({recieving_docs: true})
    if (e.target.checked) {
      if (window.confirm('Подтвердить получение подписанных документов по этой заявке?')){
        run_request = true
      } else {
        e.preventDefault();
      }
    } else {
      if (window.confirm('Убрать отметку о получении документов?')){
        run_request = true
      } else {
        e.preventDefault();
      }
    }

    if (run_request) {
      this.API.put('/orders/docsdone/'+this.nodeId, {})
              .then((response) => {
                if (this.nodeId = response) {
                  let order_data = this.state.order_data
                  order_data.order.papers_done = !order_data.order.papers_done
                  this.setState({
                    recieving_docs: false,
                    order_data
                  })
                }
              })
    } else {
      this.setState({recieving_docs: false})
    }

  }

  savePostservice = (e) => {
    e.preventDefault();
    this.setState({postservice_saving: true})
    let sent_data = {
      sent_date: this.state.order_data.sent_date,
      sent_operator: this.state.order_data.sent_operator,
      sent_number: this.state.order_data.sent_number,
    }
    this.API.put('/orders/docs/'+this.state.order_data.order.id+'/updatesent', sent_data)
      .then((responseData) => {
        if(responseData!=undefined) {
          let order_data = this.state.order_data
          order_data.sent_date = responseData.sent_date
          order_data.sent_number = responseData.sent_number
          order_data.sent_operator = responseData.sent_operator
          this.setState({
            postservice_saving: false,
            order_data
          })
        }
      })

  }


}

export default OrdersDocs
